import React from "react";
import { useState, useEffect } from "react";
import '../../styles/ArticleContent.css';
import { Link } from "react-router-dom";

export default function ArticleContent({content}) {
	const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
  let articleUrl;
  let currentArticle;

  useEffect(async () => {
    articleUrl = window.location.pathname.slice(6).replaceAll("-", " ");
    currentArticle = content.filter(e => e.title.toLowerCase() == articleUrl);
    document.title= `Revo | News - ${currentArticle[0].title}`;
    if (window.location.hostname.slice(0, 3) == 'www') {
        window.location.href = 'https://revo.network/';
    }
}, []);


  let [articleData, setArticleData] = useState("");
  const [ articleContentHandler, setArticleContentHandler ] = useState(false);

  function readMoreHandler(newCurrentArticle) {
    currentArticle = content.filter(e => e.title == newCurrentArticle);
    setArticleData(currentArticle);
    articleContentHandler ? setArticleContentHandler(false) : setArticleContentHandler(true);
    return window.scroll(0,0);
  }

  articleUrl = window.location.pathname.slice(6).replaceAll("-", " ");
  currentArticle = content.filter(e => e.title.toLowerCase().replaceAll(" - "," ") == articleUrl);
  articleUrl.length && currentArticle.length && !articleData.length && setArticleData(currentArticle);
  return (
    <div style={{ minHeight: `100vh` }}>      
      <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>



        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-10 offset-xl-1 text-center">
              <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>{articleData.length && articleData[0].title}</strong></h1>
              <p className="pa-p-v1 pa-white fadeInDelay03Duration10">{articleData.length && articleData[0].subtitle}</p>
              <p className="pa-p-v1 pa-white fadeInDelay03Duration10">{articleData.length && articleData[0].date}</p>
            </div>
          </div>
        </div>

      </section>

      <section id="services" style={{ minHeight: `50vh` }} className="pa-what-we-do pa-standard-section pa-standard-section-article text-center">
        <div className="container">
          <div className="row">
            <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">
              {articleData.length && <img style={{width: `100%`, height: `30%`}} src={articleData[0].imgUrl.toLowerCase()} />}
              <h1 className="h1-title pa-h1-v2 pa-bright fadeInDelay00Duration10"></h1>
              <span className="article-content-span"><div style={{fontFamily: `Verdana`}} className="article-div-content" dangerouslySetInnerHTML={articleData.length && {__html: (articleData[0].content).replaceAll("`","")}}></div></span>
            <div className="share-section" >
              <div className="share-container left">
              {
                articleData.length && articleData[0].hashTags.map((e,i) => {
                  return <span style={{display: `inline-block`, color: `#ffffff`}} key={e}><i class="fas fa-tag"></i>{` ${e} `}&nbsp;</span>
                })
              }
              </div>              
              <div className="share-container right">
                {/*<h2 style={{ textAlign: `right`, width: `100%`, fontSize: `18px`, marginTop: `30px` }}>Share this!</h2>*/}
                <div className="share-div">
              {/* Facebook */}
              <a class="btn btn-primary button-share" style={{ backgroundColor: `#000`, borderRadius: `20px` }} target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=https://revo.network${window.location.pathname}&quote=Take%20a%20look%20on%20this%20article%20on%20Revo`} role="button"
              ><i class="fab fa-facebook-f"></i></a>

              {/* Twitter */}
              <a class="btn btn-primary button-share" style={{ backgroundColor: `#000`, borderRadius: `20px` }} target="_blank" href={`https://twitter.com/share?text=Take%20a%20look%20on%20this%20article%20on%20Revo&url=https://revo.network${window.location.pathname}`} role="button"
              ><i class="fab fa-twitter"></i></a>

              {/* Linkedin */}
              <a class="btn btn-primary button-share" style={{ backgroundColor: `#000`, borderRadius: `20px` }} target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=https://revo.network${window.location.pathname}`} role="button"
              ><i class="fab fa-linkedin-in"></i></a>

              {/* Reddit */}
              <a class="btn btn-primary button-share button-share-last" style={{ backgroundColor: `#000`, borderRadius: `20px` }} target="_blank" href={`https://www.reddit.com/submit?url=https://revo.network${window.location.pathname}`} role="button"
              ><i class="fab fa-reddit-alien"></i></a>

            </div>

              </div>
              
            </div>
            
              <h2 className="more-news">More news</h2>
              {
                <div className="articles-container articles-cont">
                  {articleData.length && content.filter(e => e.title !== articleData[0].title).slice(0, 3).map(e => {
                    return (
                      <div key={e.title} style={{ backgroundImage: `url(${e.imgUrl.toLowerCase()})`, backgroundPosition: `top`, marginLeft: `15px`, marginRight: `15px`, height: `350px` }} className="article pa-hero pa-centered-section pa-full-height pa-image-back pushable-content hc-first-section">
                        <Link className="article-link" to={`/news/${e.title.toLowerCase().replaceAll(" - "," ").replaceAll(" ", "-")}`} onClick={() => readMoreHandler(e.title)}>Read More</Link>
                        <div className="date-container"><span>{e.date}</span></div>
                        <div className="title-container">
                          <h4>{e.title}</h4>
                          <h5>{e.subtitle}</h5>
                        </div>
                        <div className="article-back"></div>
                      </div>
                    )
                  })
                  }
                </div>}

            </div>
          </div>
          
        </div>
      </section>
    </div>
  )
}