import React from "react";
import { useEffect } from "react";

export default function Transaction() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    
    useEffect(() =>{
        document.title= `Revo | API - Transaction`
      }, []);     

    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Transaction</h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Display information data of specific blockchain transactions or send raw transaction</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Transaction</h1>
                            <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                            <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>

                            <font color="white" style={{fontSize: `26px !important`}}>Table of contents</font><br></br>
                            <ul style={{color: `white`}}>
                                <li><b><a style={{color: `white`}} href="#transaction">Transaction</a></b>
                                    <ul>
                                        <li><a style={{color: `white`}} href="#transaction-information">Single Transaction Information</a></li>
                                        <li><a style={{color: `white`}} href="#raw-transaction">Raw Transaction</a></li>
                                        <li><a style={{color: `white`}} href="#send-raw-transaction">Send Raw Transaction</a></li>
                                    </ul>
                                </li>
                            </ul>

                            <br></br>
                            <font color="white" style={{fontSize: `26px !important`}}>Transactions</font>
                            <p className="pa-p-v1 pa-bright">Get information data of specific blockchain transactions or send raw transaction</p>
                            <font id="transaction-information" color="white">Single Transaction Information</font>
                            <table style={{padding: `10px`, width: `100%`}}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/tx/:id</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display full transaction information using a specific tx id</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/tx/21770e3cabe0896c4dc68756c0ed2c54ed6568cfb8e8b58278403d543fadaae8" target="_blank">https://api.revo.network/tx/21770e3cabe0896c4dc68756c0ed2c54ed6568cfb8e8b58278403d543fadaae8</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="raw-transaction" color="white">Raw Transaction</font>
                            <table style={{width: `100%`}}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/raw-tx/:id</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display full raw transaction code starting from a specific tx id</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/raw-tx/21770e3cabe0896c4dc68756c0ed2c54ed6568cfb8e8b58278403d543fadaae8" target="_blank">https://api.revo.network/raw-tx/21770e3cabe0896c4dc68756c0ed2c54ed6568cfb8e8b58278403d543fadaae8</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="send-raw-transaction" color="white">Send Raw Transaction (Broadcast)</font>
                            <table style={{width: `100%`}}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[POST] <br></br>Request</span> : "[POST] Request"}</font></td>
                                    <td w><font className="ap-first-h4">/tx/send</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Broadcast raw signed transaction. Argument accepted: hex value for raw tx</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <font color="white">Request Body:<br></br> rawtx=02000000014727d9d3560b94b0cf1c10daea4<br></br>3920c24fa8451a75f2d5daea4f69f6f585726fcb15....</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/tx/send/02000000014727d9d3560b94b0cf1c10daea43920c24fa8451a75f" target="_blank">https://api.revo.network/tx/send/02000000014727d9d3560b94b...</a><br></br>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    )
}
