import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Address from "./apidocs/Address";
import Block from "./apidocs/Block";
import Blockchain from "./apidocs/Blockchain";
import BlockTimestampFilter from "./apidocs/BlockTimestampFilter";
import Contract from "./apidocs/Contract";
import Erc20 from "./apidocs/Erc20";
import Erc721 from "./apidocs/Erc721";
import PaginationParameters from "./apidocs/PaginationParameters";
import Transaction from "./apidocs/Transaction";
import Footer from "./Footer";
import Header from "./Header";
import '../styles/ApiDocs.css';

export default function ApiDocs() {

    const navigate = useNavigate();

    useEffect(() => {
        let target = window.location.pathname;
        let address = ['blockchain', 'block', 'transaction', 'address', 'contract', 'erc20', 'erc721', 'pagination-parameters', 'block-timestamp-filters'];
        if (target.length <= 10) {
            navigate('/');
            window.location.reload();
        }else if(!address.includes(target.slice(10))){
            navigate('/');
            window.location.reload();
        }
    },[])

    
    
    const [ loadingState, setLoadingState ] = useState(false);
    
 	setTimeout(function() {
        setLoadingState(true);
 	}, 1000);


    return (
        <div>
        <div style={loadingState ? {opacity: `0`, display: `none`} : {}} className="pa-loader">
            <section className="warper"><div className="spinn" style={{opacity: `0.24`}}><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div><div className="preloader"><p className="hello"></p><div id="preloader"><div id="loader"></div></div></div></section>
        </div>
            <Header />
            {
                window.location.pathname.slice(10) == 'blockchain' && <Blockchain />
            }{
                window.location.pathname.slice(10) == 'block' && <Block />
            }{
                window.location.pathname.slice(10) == 'transaction' && <Transaction />
            }{
                window.location.pathname.slice(10) == 'address' && <Address />
            }{
                window.location.pathname.slice(10) == 'contract' && <Contract />
            }{
                window.location.pathname.slice(10) == 'erc20' && <Erc20 />
            }{
                window.location.pathname.slice(10) == 'erc721' && <Erc721 />
            }{
                window.location.pathname.slice(10) == 'pagination-parameters' && <PaginationParameters />
            }{
                window.location.pathname.slice(10) == 'block-timestamp-filters' && <BlockTimestampFilter />
            }
            <Footer />
        </div>
    )
}