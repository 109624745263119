import React from "react";
import '../../styles/MembersContent.css';

export default function NewsContent() {
	const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
	const nicola = `https://${window.location.hostname}/images/members/nicola.png`;
	const miodrag = `https://${window.location.hostname}/images/members/miodrag.png`;
	const goran = `https://${window.location.hostname}/images/members/goran.png`;
	const marcozorzan = `https://${window.location.hostname}/images/members/marcozorzan.png`;
	const cesarecarli = `https://${window.location.hostname}/images/members/cesarecarli.png`;
	const pablolizarraga = `https://${window.location.hostname}/images/members/pablolizarraga.png`;
	const chrislu = `https://${window.location.hostname}/images/members/chrislu.png`;
	const gianpieroleoni = `https://${window.location.hostname}/images/members/gianpieroleoni.png`;
	const noprofile = `https://${window.location.hostname}/images/members/noprofile.png`;
	
    let members = [
		{ name: "Nicola Bertelli",
		  description: "Nicola has been involved in IT and blockchain development since 2014. During his career he worked as technician on behalf of Unisys, Sisal and Dell, especially in the sys and network areas..", 
		  profession: "CEO & Founder - Core Developer", 
		  img: nicola,
		  linkedin: "https://www.linkedin.com/in/nicola-bertelli-a6b145188/"
		},    
		
		{ name: "Miodrag Popovic", 
		  description: "Miodrag is a long-time developer, in recent years thanks to his knowledge in software programming he has approached the world of blockchain development thanks to ZeroClassic..", 
		  profession: "Core Developer", 
		  img: miodrag,
		  linkedin: "#"
		},
		
		{ name: "Goran Apostoloski", 
		  description: "Goran Apostoloski is a passionate graphic designer and web developer, who uses all Adobe software solutions to create beautiful designs. Involved in crypto since 2013..", 
		  profession: "Design & Web", 
		  img: goran,
		  linkedin: "https://www.linkedin.com/in/cryptodesign/" 
		},    
		
		{ name: "Marco Giovanni Zorzan", 
		  description: "Marco has many years of experience in the IT field, gained in many sectors. Developer and technician in the automation, iOT and management software sector. An expert in C / C ++, Visual Basic, Java..", 
		  profession: "Software Developer", 
		  img: marcozorzan,
		  linkedin: "https://www.linkedin.com/in/marco-z-a8a817215/" 
		},
		
		{ name: "Cesare Carli", 
		  description: "Cesare Carli is an IT consultant and professional trainer. Technology and Free Software evangelist since the late 1990s, he is strongly committed to spread knowledge about possibilities and applications in the blockchain field...", 
		  profession: "Training", 
		  img: cesarecarli,
		  linkedin: "https://www.linkedin.com/in/cesare-carli-24807ab/" 
		},
			
		{ name: "Pablo Lizárraga", 
		  description: "Pablo is passionate about the world of information technology. In 2015 he began his engineering career in information systems. In the third year of his degree, he decided to focus entirely on programming..", 
		  profession: "Frontend Developer", 
		  img: pablolizarraga,
		  linkedin: "https://www.linkedin.com/in/pablo-matias-lizarraga/"
		},
		
		{ name: "Chris Lu", 
		  description: "Chris Lu is a software engineer, developer of SeaWeedFS, a completely open source software that allows the creation of distributed data storage networks in a very efficient and distributed way..", 
		  profession: "Developer - Advisor", 
		  img: chrislu,
		  linkedin: "https://www.linkedin.com/in/chrislu/" 
		},    
		
		{ name: "Gian Piero Leoni", 
		  description: "Gian Piero entered into the crypto world in 2012, back in the days when mining was predominantly cpu/gpu and early home ASICs. During these years he met a lot of people from the BitcoinTalk forum..", 
		  profession: "Community Manager", 
		  img: gianpieroleoni,
		  linkedin: "#" 
		}
	]

    return (
        <div style={{ minHeight: `100vh` }}>
            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Team Members</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Latest news from revo technologies</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id="services" style={{ minHeight: `50vh`, maxWidth: `100%`}} className="pa-what-we-do pa-standard-section text-center">
                <div className="row">
                    <div style={{ display: `none` }} align="left" className="col-12 col-xl-10 offset-xl-1">
                        <h1 className="h1-title pa-h1-v2 pa-bright fadeInDelay00Duration10"></h1>
                    </div>

                    <div className="container row featured-1 mb-5">{
                        <div className="memb-container">
                            {members.map(e => {
                                return (
                                    <div key={e.name} className="memb-card pa-hero pa-centered-section pa-full-height pa-image-back pushable-content ">
                                        <img style={{ width: `100%`, height: `300px`, padding: `0` }} src={e.img ? e.img : noprofile} />
                                        <div className="title-container">
                                            <h4 style={{color: `#ffffff`}}>{e.name}</h4>
                                            <div className="date-container"><span>{e.profession}</span></div>
                                            <h5>{e.description}</h5>
                                        </div>
                                        {/* Linkedin */}
                                        <a class="btn btn-primary linkedin" style={{ backgroundColor: `transparent`, position: `absolute`, bottom: `0`, border: `none` }} target="_blank" href={e.linkedin} role="button"
                                        ><i class="fab fa-linkedin-in" ></i></a>
                                    </div>
                                )
                            })
                            }
                        </div>}
                    </div>
                </div>
            </section>
        </div>
    )
}