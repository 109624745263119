import React from "react";
import '../styles/AlternativePages.css';
import { useNavigate } from "react-router-dom/dist";

export default function Error404() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const error404Img = `https://${window.location.hostname}/images/error404.svg`;
    let navigate = useNavigate();

    return (
        <div>

            <section id="top" style={{ backgroundImage: `url(${nebulosa})` }} className="pa-hero pa-centered-section pa-full-height pa-image-back pushable-content hc-first-section" >
                
            <div className="coming-soon-container" style={{width: `100%`, marginTop: `-15%`}}>
                <img className="error404Img" src={error404Img} style={{ height: `auto`, padding: `0`, marginBottom: `20px`}} />
                <h4>You didn't break the internet, but we can't find what you are looking for.</h4>
                <button className="" onClick={() => navigate(-1)}>Go back!</button>
            </div>
            </section>
        </div>
    )
}