import React from "react";
import { useEffect } from "react";

export default function PoS() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const split_example = `https://${window.location.hostname}/images/technology/split-example.PNG`;
    const super_staking = `https://${window.location.hostname}/images/technology/super-staking.PNG`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - Proof Of Stake`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Proof Of Stake</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Proof-of-stake is a cryptocurrency consensus mechanism for processing transactions and creating new blocks in a blockchain.</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Light Consensus model</h1>
                            <p className="pa-p-v1 pa-bright">Proof-of-stake is a cryptocurrency consensus mechanism for processing transactions and creating new blocks in a blockchain. A consensus mechanism is a method for validating entries into a distributed chain and keeping the database secure. In the case of cryptocurrency, the database is called a blockchain—so the consensus mechanism secures the blockchain. Proof-of-stake reduces the amount of computational work needed to verify blocks and transactions that keep the blockchain, and thus a cryptocurrency, secure. Proof-of-stake changes the way blocks are verified using the machines of coin owners</p>
                            <p className="pa-p-v1 pa-bright">The owners offer their coins as collateral for the chance to validate blocks. Coin owners with staked coins become "validators." Validators are then selected randomly to "mine," or validate the block. This system randomizes who gets to "mine" rather than using a competition-based mechanism like proof-of-work. To become a validator, a coin owner must "stake" a specific amount of coins.</p>
                            <p className="pa-p-v1 pa-bright">Under PoS, block creators are called validators. A validator checks transactions, verifies activity, votes on outcomes, and maintains records while under PoW, the creators are called miners. Miners solve complex mathematical problems to verify transactions. To "buy into" the position of becoming a block creator, miners need only to get the sufficient limit of coins or tokens required to become a validator for a PoS blockchain. For PoW, miners must invest in processing equipment and incur heavy energy charges to power the machines attempting to solve the computations.</p>
                            <p className="pa-p-v1 pa-bright">The equipment and energy cost under PoW mechanisms are expensive, limiting access to mining and strengthening the security of the blockchain. However, PoS blockchains often allow for more scalability due to their energy efficiency.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Objectives</h1>
                            <p className="pa-p-v1 pa-bright">Proof-of-stake is designed to reduce the scalability and environmental sustainability concerns surrounding the proof-of-work (PoW) protocol. Proof-of-work is a competitive approach to verifying transactions, which naturally encourages people to look for ways to gain an advantage, especially since monetary value is involved.</p>
                            <p className="pa-p-v1 pa-bright">Bitcoin miners earn Bitcoin by verifying transactions and blocks. However, they pay their operating expenses like electricity and rent with fiat currency. What's really happening then is that miners are exchanging energy for cryptocurrency. The amount of energy required to mine proof-of-work cryptocurrency profoundly affects the market dynamics of pricing and profitability. There are also environmental aspects to consider since PoW mining uses as much energy as a small country.</p>
                            <p className="pa-p-v1 pa-bright">The PoS mechanism seeks to solve these problems by effectively substituting staking for computational power, whereby an individual's mining ability is randomized by the network. This means there should be a drastic reduction in energy consumption since miners can no longer rely on massive farms of single-purpose hardware to gain an advantage.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Security</h1>
                            <p className="pa-p-v1 pa-bright">Long touted as a threat for cryptocurrency fans, the 51% attack is a concern when PoS is used, but it is very unlikely. A 51% attack is when someone controls 51% of a cryptocurrency and uses that majority to alter the blockchain. In PoS, a group or individual would have to own 51% of the staked cryptocurrency.</p>
                            <p className="pa-p-v1 pa-bright">It is not only very expensive to have 51% of the staked cryptocurrency—staked currency is collateral for the privilege to "mine." The miner(s) that attempt to revert a block through a 51% attack would lose all of their staked coins. This creates an incentive for miners to act in good faith for the benefit of the cryptocurrency and the network.</p>
                            <p className="pa-p-v1 pa-bright">Most other security features of PoS are not advertised, as this might create an oportunity to circumvent security measures. However, most PoS systems have extra security features in place that add to the inherent security behind blockchains and the PoS mechanisms.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Reward</h1>
                            <p className="pa-p-v1 pa-bright">Revo uses PoS consensus model to check, verify and validate it's own transactions. To become a Revo validator, miners should have matured UTXOs, suggested amount is 100 RVO.</p>
                            <p className="pa-p-v1 pa-bright">A single UTXOs should have atleast 500 confirmations to become elegible for staking. Revo offers a user friendly set of commands that allow miners to optimize their staking capabilities and chances to validate blocks. Miners can issue the command <strong>splitutxosforaddress</strong> to split their biggest UTXOs into custom sizes. Bigger UTXOs get's automatically splitted up to certain level each time they stake.</p>
                            <p className="pa-p-v1 pa-bright"><strong>splitutxosforaddress "address" minValue maxValue ( maxOutputs )</strong></p>
                            <img style={{width: '40%', height: '30%'}} src={split_example}/>
                            <p className="pa-p-v1 pa-bright">For this example, the total input was 1,020 RVO, and the split was 9 UTXOs of 100.0 and one of 119.99566, the wallet sending a "transaction to self". Of course, after either of these commands, the UTXOs must mature for 2000 confirmations before they can be used for staking.</p>
                            <h1 id="offline-staking" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Offline Staking (Contract Delegation)</h1>
                            <p className="pa-p-v1 pa-bright">Revo Offline Staking allows the address for a non-staking wallet (capable of making the delegation assignment transaction) to be delegated to a Super Staker.</p>
                            <p className="pa-p-v1 pa-bright">Offline Staking is non-custodial: the delegation user keeps full control of their coins and private keys. The address delegation is made via a smart contract transaction from the delegation user's wallet which identifies the delegator's address, the Super Staker address, and the fee the delegator agrees to pay. If the Super Staker accepts this fee, it will begin staking the delegated address UTXOs. The normal rules for staking UTXOs apply to delegated UTXOs:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">UTXOs may only be used for staking after they mature (500 confirmations)</p></li>
								<li><p className="pa-p-v1 pa-bright">The Super Staker will set a minimum size of UTXOs to stake, defaulting to 100 RVO. Delegated UTXOs below this amount will be ignored.</p></li>
                                <li><p className="pa-p-v1 pa-bright">It is best practice (for optimum returns) to break UTXOs up into sizes of 100 to 200 RVO each. For users of the Revo Core wallet, this can be easily accomplished with the command line version of splitutxosforaddress, described below.</p></li>
                            </ul>
                            <img style={{width: '80%', height: '30%'}} src={super_staking}/>
                            <p className="pa-p-v1 pa-bright">The Super Staker must hold UTXOs to commit to stakes for the delegated UTXOs it is staking. The number of UTXOs (of minimum size 100 RVO) is based on Delegated Weight as a percentage of overall Network Weight, and good values are 30 UTXOs for staking 1% of Network Weight, 50 UTXOs for 2.0%, 100 UTXOs for 5% and 160 UTXOs for staking 10% of overall Network Weight. Super Stakers should monitor their Wallet weight (UTXO weight minus the amount currently staking) and add UTXOs if it drops below several thousand.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Technicals (Proof of Delegation)</h1>
                            <p className="pa-p-v1 pa-bright">The proof of delegation ("PoD") is a compact 65 byte signature made by the <strong>delegation{"{privkey}"}</strong> with the staker<strong>{"{hexpubkeyhash}"}</strong> as a message. This is done in order to prove that the delegator has at some point allowed the staker to stake blocks on behalf of the delegator. More specifically, the message that is signed is:</p>
                            <p className="pa-p-v1 pa-bright"><strong>sha256(staker{"{hexpubkeyhash}"})</strong></p>
                            <p className="pa-p-v1 pa-bright">This functionality will be activated automatically on the Revo Blockchain Protocol starting from block 21,000,000.</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
