import React from "react";
import '../styles/AlternativePages.css';
import { useNavigate } from "react-router-dom/dist";

export default function ComingSoon() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const comingSoonImg = `https://${window.location.hostname}/images/coming-soon.png`;
    let navigate = useNavigate();

    return (
        <div>

            <section id="top" style={{ backgroundImage: `url(${nebulosa})` }} className="pa-hero pa-centered-section pa-full-height pa-image-back pushable-content hc-first-section" >
                
            <div className="coming-soon-container" style={{width: `100%`, marginTop: `-5%`}}>
                <img className="comingsoonImg" src={comingSoonImg} style={{ height: `auto`, padding: `0`, marginBottom: `20px`}} />
                <h1><strong>Coming Soon!</strong></h1>
                <h4>What is this? Maybe something cool is coming!</h4>
                <button className="" onClick={() => navigate(-1)}>Go back!</button>
            </div>
            </section>
        </div>
    )
}