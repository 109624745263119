import React from "react";
import { useEffect } from "react";

export default function DS() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const seaweedfs_model = `https://${window.location.hostname}/images/technology/seaweedfs-mode.PNG`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - Decentralized Storage`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Decentralized Storage</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Virtual Datacenter</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Decentralized Storage</h1>
                            <p className="pa-p-v1 pa-bright">With the rising interest in blockchain technology, interest has also risen for the one killer Blockchain app. We believe that the blockchain killer app will be decentralized storage technology.</p>
                            <p className="pa-p-v1 pa-bright">From solving our challenges of safely storing data to not having all of our personal info in the hands of a few monolithic entities, decentralized storage has the potential to be both disruptive and help usher in even greater use of blockchain technology. When it comes to data storage, here are our options:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">Physical media. Think HDDs to share films with friends or USB thumb drives for small file storage.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Centralized cloud storage. Our data is hosted on a central cloud owned and operated by someone that isn’t you. Your data belongs to them. Sometimes it isn’t even safe.</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright">On the other hand, decentralized cloud storage is where data is stored on a decentralized network across multiple locations by users or groups who are incentivized to join, store, and keep data accessible. The servers used are hosted by people, rather than a single company. Anyone is free to join, they are kept honest due to smart contracts, and they are incentivized to participate by getting coins as rewards</p>
                            <p className="pa-p-v1 pa-bright">Thanks to the integrated technology on Revo, it will also be possible to create private instances and customized shared storage pools.</p>
                            <p className="pa-p-v1 pa-bright">There are several reasons for the advent of decentralized storage over centralized cloud storage.</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">Data breaches: Just about all the major players have had data breaches in the last few years.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Data outages: Dreaded DDoS attacks can leave you and millions of others without data.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Rising storage costs: This means high bandwidth transmission costs, as well as increased data security costs as centralized storage, is easier to attack.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Lack of ownership: Your personal private data is not owned only by you.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Censorship and monitoring: While there are some benefits associated with this, many people disagree with censorship and surveillance especially if it approaches Orwellian levels.</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright">Furthermore, with the industry expected to reach 101 $billion+ by 2023, there is a significant need for these problems to be solved. We, like many others, believe decentralized cloud storage can do that.</p>
                            <p className="pa-p-v1 pa-bright">Decentralized storage means the files are stored on multiple computers (called nodes) on a decentralized network. Like with conventional cloud storage, when you need a file, you can request it and receive the file. Requesting your file works similarly to BitTorrent and other P2P clients where you download fragments of that file from participants in the network until you have the full file.</p>
                            <p className="pa-p-v1 pa-bright">But that doesn’t mean those holding your files can read them. Instead, decentralized storage automatically encrypts files and only you hold the encryption key, guaranteeing your files can only be read by you. Furthermore, through a process of sharding, no single person holding your files has the entirety of it, thus adding an extra layer of security and protection. Unlike centralized cloud storage which keeps data in a central point based on a location that might not be near you (resulting in users competing for bandwidth), the nature of decentralized storage means data distribution and retrieval is handled by nearby peers regardless of physical location. This results in higher transfer speeds due to utilizing local network bandwidth.</p>
                            <h1 id="model-technology" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Model technology</h1>
                            <p className="pa-p-v1 pa-bright">The software behind Revo's virtual datacenter system is SeaweedFS a completely open source project developed by Chrislusf. It's a distributed storage system for blobs, objects, files, and data. A data warehouse with predictable low latency with O(1) disk seek, and flexible data placement with multiple tiers and cloud tiers</p>
                            <img style={{width: '80%', height: '30%'}} src={seaweedfs_model}/>
                            <p className="pa-p-v1 pa-bright">SeaweedFS is built into multiple layers:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">Blob Storage consists of master , volume servers, and cloud tier.</p></li>
                                <li><p className="pa-p-v1 pa-bright">File Storage consists of the Blob Storage and filer servers.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Object Storage consists of the File Storage and S3 servers.</p></li>
                                <li><p className="pa-p-v1 pa-bright">Data warehouse consists of the File Storage and Hadoop compatible libraries, used by HDFS, Hadoop, Spark, Flink, Presto, HBase, etc.</p></li>
                                <li><p className="pa-p-v1 pa-bright">FUSE Mount consists of the File Storage mounted to the user space on clients, used in common FUSE mount, Kubernetes persistent volume, etc.</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright">SeaweedFS is:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">A fast key to file mapping with O(1) disk seek.</p></li>
                                <li><p className="pa-p-v1 pa-bright">A customizable tiered storage placing data on demand.</p></li>
                                <li><p className="pa-p-v1 pa-bright">An elastic storage system that offloads less active data to cloud.</p></li>
                                <li><p className="pa-p-v1 pa-bright">A scalable file system to replace HDFS.</p></li>
                                <li><p className="pa-p-v1 pa-bright">A high-performance in-house S3 compatible object store.</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright"><strong>High data and service availability:</strong></p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">No single point of failure</p></li>
                                <li><p className="pa-p-v1 pa-bright">Supports Active-Active asynchronous replication</p></li>
                                <li><p className="pa-p-v1 pa-bright">Supports Erasure Coding</p></li>
                                <li><p className="pa-p-v1 pa-bright">Supports file checksum</p></li>
                                <li><p className="pa-p-v1 pa-bright">Supports rack and data center aware replication</p></li>
                                <li><p className="pa-p-v1 pa-bright">Supports metadata backup and replication</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright"><strong>High data and service availability:</strong></p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">Optimized for lots of small files. Each file overhead is 40 bytes. SeaweedFS Architecture 3 Always O(1) disk read, even for Erasure-Coded data</p></li>
                                <li><p className="pa-p-v1 pa-bright">Linear scalable. No HDFS name node bottleneck</p></li>
                                <li><p className="pa-p-v1 pa-bright">Clients access data directly on volume servers</p></li>
                                <li><p className="pa-p-v1 pa-bright">File servers are accessed for meta data</p></li>
                                <li><p className="pa-p-v1 pa-bright">Place data by requirements to custom disk types, e.g., NVME, SSD, HDD</p></li>
                            </ul>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Volume Server</h1>
                            <p className="pa-p-v1 pa-bright">The volume servers stores a bunch of volumes. Each volume defaults to 30GB. The disk space can be pre-allocated. All the writes are append only. The volumes are compacted periodically. So there is not much disk space wasted.Each volume can contain lots of files. Each file is indexed by its offset in a separate volume index. So each file read request will just read the in-memory volume index for the offset and size, and then read the volume file with a single disk seek.</p>
                            <p className="pa-p-v1 pa-bright">Volumes can have different replication settings. Each write to a volume will be replicated to its peers with strong consistency. If any replica fails to write, the whole request fails. Volumes also have different TTL settings. The TTL volumes are not compacted, but just purged as a whole if all the entries in a volume past the TTL. Volumes can be tagged as different disk types, such as SSD, HDD, or actually any tags, such as NVME, fastHDD, slowHDD. The data is placed according to the write request requirement. The admin scripts can move the data to other tiers.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Master Server</h1>
                            <p className="pa-p-v1 pa-bright">The master servers track volume locations on all of the volume servers. This information is dynamically collected as soft states and never persisted. This info is provided to clients as a DNS to locate each volume.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Plan on Revo integrations</h1>
                            <p className="pa-p-v1 pa-bright">The SeaweedFS integration plan into Revo sees the object storage mode as the primary focus. To guarantee a complete decentralization and autonomous management via smart contract, the aforementioned distributed DNS system will take care of being the connection point between master servers and volume servers.</p>
                            <p className="pa-p-v1 pa-bright">The nodes that want to contribute to the storage network will be able to participate in it by initially creating a network identifier (in our case a decentralized domain), with which they will choose whether to become a master server or a volume server.</p>
                            <p className="pa-p-v1 pa-bright">However, there are minimum requirements depending on the type of node chosen. It is very important that both types of nodes have good network connectivity via cable and that they are able to withstand a few hours even in the event of a blackout, therefore a UPS is seen as necessary. It will be essential to have a static and public IP address with NAT 1: 1 for the correct routing of data packets.</p>
                            <p className="pa-p-v1 pa-bright">New startup flags will be implemented directly in the revo daemon code:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright"><strong>--storage-enable=true / false</strong><br />This command allows you to enable the storage functionality on your Revo node. This command is launched to allow the daemon to install and configure all the libraries necessary for the proper functioning of the storage system</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>--storage-upgrade</strong><br />Command required for storage software LTS update (Long Term Support)</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>--storage-issue-new=master / volume</strong><br />Startup command that starts the wizard that allows the miner to create a unique identifier based on the type of storage mode chosen. At the end of the procedure, a storage.conf file will be created in the .revo folder (or %AppData% / Roaming / Revo on windows) containing the complete configuration for storage mode.</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>--storage-mode=master / volume</strong><br />Start command that specifies how the daemon will be run.</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>--storage-path=path</strong><br />Needed to specify which directory to allocate to storage mode.</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>--storage-bind=public IP: PORT</strong><br /></p>Command to customize interface and the public network port.</li>
                            </ul>
                            <p className="pa-p-v1 pa-bright">In addition to these basic commands, specific functions will be implemented where each individual user can personalize the amount of space (in gigabytes) to be made available, the amount of volumes to be pre-generated, etc.</p>
                            <h1 id="public-personal-storage-pools" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Public and Personal storage pools</h1>
                            <p className="pa-p-v1 pa-bright">Basically, the storage mode on Revo is created specifically for a type of public and shared use which allows it, through a complex reward mechanism, to make storage space available for everyone. Thanks to the intrinsic functionality of SeaweedFS, it will also be possible to start the storage daemon in offline mode, and create storage pools and data sharing locally, taking full advantage of all of the native features of SeaweedFS</p>
                            <h1 id="reward-system" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Reward System</h1>
                            <p className="pa-p-v1 pa-bright">The reward system for the storage mode is entirely managed by smart contract code. Thanks to the work done in unison by the volume server and master server, the owners will receive tokens as remuneration for the service based on:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">uptime</p></li>
                                <li><p className="pa-p-v1 pa-bright">amount of allocated storage space</p></li>
                                <li><p className="pa-p-v1 pa-bright">long-term conservation</p></li>
                                <li><p className="pa-p-v1 pa-bright">data transferred / served</p></li>
                                <li><p className="pa-p-v1 pa-bright">network reliability</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright">The task of the master servers will also be to redirect data backup requests to the closest nodes (with lower latency), to ensure content redundancy and network reliability.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Objectives</h1>
                            <p className="pa-p-v1 pa-bright">This type of distributed management allows the creation of a public environment with a precise system of virtually unlimited space, capable of handling and sharing any type of file, in an encrypted and redundant manner. The files will therefore be accessible at any time and from anywhere in the world making it censor free.</p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
