import React, { useEffect, useState } from "react";
import "../../styles/assets/vendor/animate/animate.min.css"
import ParticleJs from "../addons/ParticlesJs";
import '../../styles/HomeContent.css'
import FadeInSection from "../addons/FadeInSection";
import { Link } from "react-router-dom";
import ProductCards from "../addons/ProductCards";

export default function HomeContent() {
	const timeLine = `https://${window.location.hostname}/images/timeline.PNG`
	const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
	const raspberry = `https://${window.location.hostname}/images/raspberry.png`;
	const linux = `https://${window.location.hostname}/images/linux.png`;
	const windows = `https://${window.location.hostname}/images/windows.svg`;
	const apple = `https://${window.location.hostname}/images/apple.svg`;
	const raspberryicon = `https://${window.location.hostname}/images/raspberryicon.png`;
	const android = `https://${window.location.hostname}/images/android.png`;
	const github = `https://${window.location.hostname}/images/github.png`;
	const node = `https://${window.location.hostname}/images/node.png`;
	const nodeInterface = `https://${window.location.hostname}/images/node-interface.png`
	const microNode = `https://${window.location.hostname}/images/node-micronode.png`

	const [currentPhrase, setCurrentPhrase] = useState(['Public Blockchain', '1']);
	const [phraseState, setPhraseState] = useState('asd');

	useEffect(() => {
		setPhraseState(true);
		changePhrase();
	}, [currentPhrase, phraseState]);



	function changePhrase() {
		if (currentPhrase[0] == 'Public Blockchain') {
			setTimeout(function () {
				setCurrentPhrase(['Decentralized Ecosystem', '2']);
			}, 6000);
			setPhraseState(false);
		} else if (currentPhrase[0] == 'Decentralized Ecosystem') {
			setTimeout(function () {
				setCurrentPhrase(['Built in shared governance protocol', '3']);
			}, 6000);
			setPhraseState(false);
		} else if (currentPhrase[0] == 'Built in shared governance protocol') {
			setTimeout(function () {
				setCurrentPhrase(['Fully EVM compatible', '4']);
			}, 8000);
			setPhraseState(false);
		} else if (currentPhrase[0] == 'Fully EVM compatible') {
			setTimeout(function () {
				setCurrentPhrase(['Public Blockchain', '1']);
			}, 6000);
			setPhraseState(false);
		}
	}

	phraseState && changePhrase();
const dataArr = [];
/*const dataArr = [
	{
		title: "REVO - Early Adopters Node - 2x 500 GB SSD",
		img: node,
		text: "Each model comes with a unique NFC tag that identifies the user and qualifies them for the exclusive..",
		price: "€1,200.00",
		link: "https://join.revo.network/index.php?route=product/product&path=59&product_id=50"
	},
	{
	title: "REVO - Early Adopters Hosted VNode",
	img: nodeInterface,
	text: "Each Virtual Node comes with a unique NFC tag that identifies the user and qualifies them for the ex..",
	price: "€585.00",
	link: "https://join.revo.network/index.php?route=product/product&path=59&product_id=51"
},
	{
		title: "REVO - MicroNode - 128GB",
		img: microNode,
		text: "Each MicroNode comes with a unique NFC tag that identifies the user and qualifies them for the exclu..",
		price: "€169.90",
		link: "https://join.revo.network/index.php?route=product/product&path=59&product_id=52"
	},
]
*/

	return (
		<div>

			{/* Hero Section
	================================================== */}
			<section id="top" style={{ backgroundImage: `url(${nebulosa})` }} className="pa-hero pa-centered-section pa-full-height pa-image-back pushable-content hc-first-section" >

				{/*<div className="pa-absolute-fill pa-gradient-back-v1 hc-first-div" ></div> Increase opacity to overlay hero with a gradient */}
				<div id="particles-js"><ParticleJs />
				</div>

				<div className="container pa-hero-content text-center">
					<div className="row pa-hero-typed-text">
						<div className="col-12">
							<div className="typed-text mega-typed">
								<span className="animated-text-effect">
									<div className="animated-text-container">
										{
											currentPhrase[1] !== '3' ? <span className={'phrase' + currentPhrase[1]}>{currentPhrase[0]}<span className="typed-cursor">|</span></span>
												:
												<div>
													<span className={'phrase3'}>{currentPhrase[0].slice(0, 16)}<span className="typed-cursor"></span></span>
													<span className={'phrase3-2'}>{currentPhrase[0].slice(16)}<span className="typed-cursor">|</span></span>
												</div>
										}
									</div>
								</span>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12 hc-second-div">
							<h2 className="pa-h2-v2-hero-subhead pa-white">Enhancing blockchain technology <br></br> with innovative <strong>PoS v3</strong> consensus algorithm. <br></br>Notarize, store data, verify.</h2>
						</div>
					</div>
				</div>

			</section>
			<div style={{ display: `flex`, margin: `-150px auto 0 auto`, position: `relative` }}>
				<div style={{ margin: `auto` }}>
					<div className="hold-wrap" style={{ margin: `auto`, color: `white`, display: `flex` }}>
						<div style={{ width: "33%", color: `white` }}>
							<img src={windows} />
							<h5 style={{ fontSize: `1rem` }}>Windows</h5>
							<a target='_blank' href="https://github.com/revolutionchain/revo-public-release/releases/download/Mercury-0.22.1/Revo-Mercury-0.22.1-Windows-x64-GUI.zip" style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white` }}>Revo Core</a>
							<a target='_blank' href="https://github.com/revolutionchain/revo-public-release/releases/download/Mercury-0.22.1/Revo-Mercury-0.22.1-Windows-x64-Daemon+CLI.zip" style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white`, display: "block" }}>Daemon + CLI</a>
						</div>
						<div style={{ width: "33%", color: `white` }}>
							<img src={linux} />
							<h5 style={{ fontSize: `1rem` }}>Linux</h5>
							<a target='_blank' href="https://github.com/revolutionchain/revo-public-release/releases/download/Mercury-0.22.1/Revo-Mercury-0.22.1-Linux-x64-GUI.zip" style={{ display: `block`, marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white` }}>Revo Core</a>
							<a target='_blank' href="https://github.com/revolutionchain/revo-public-release/releases/download/Mercury-0.22.1/Revo-Mercury-0.22.1-Linux-x64-Daemon+CLI.zip" style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white` }}>Daemon + CLI</a>
						</div>
						<div href="#" style={{ width: "33%", cursor: `pointer`, color: `white` }}>
							<img src={apple} />
							<h5 style={{ fontSize: `1rem` }}>Mac</h5>
							<h5 style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200` }}>Coming Soon</h5>
						</div>
						<div style={{ width: "33%", color: `white` }}>
							<img src={raspberryicon} />
							<h5 style={{ fontSize: `1rem` }}>Raspberry</h5>
							<a target='_blank' href="https://github.com/revolutionchain/revo-public-release/releases/download/Mercury-0.22.1/Revo-Mercury-0.22.1-Raspbian-x64-Daemon+CLI.zip" style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white` }}>Daemon + CLI</a>
						</div>
						<div href="#" style={{ width: "33%", cursor: `pointer`, color: `white` }}>
							<img src={android} />
							<h5 style={{ fontSize: `1rem` }}>Android</h5>
							<h5 style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200` }}>Coming Soon</h5>
						</div>
						<div href="#" style={{ width: "33%", cursor: `pointer`, color: `white` }}>
							<img src={github} />
							<h5 style={{ fontSize: `1rem` }}>Github</h5>
							<a target="_blank" href="https://github.com/revolutionchain/revo-public-release/releases/" style={{ display: `block`, marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white` }}>All Releases</a>
							<a target="_blank" href="https://github.com/revolutionchain/revo" style={{ marginBottom: `0`, fontSize: `1rem`, fontWeight: `200`, color: `white` }}>Source Code</a>
						</div>
					</div>
				</div>
			</div>

			<section style={{ paddingTop: `150px`, paddingBottom: `140px` }} id="srvices" className="pa-what-we-do pa-standard-section text-left hc-second-section">

				<div className="container">
					{/* Section Header */}
					<div className="row">
						<div className="col-12 col-xl-10 offset-xl-1 text-center">
							<FadeInSection>
								<h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10 "><i className="fas fa-cubes hc-first-i"></i> Decentralized <strong className="hc-first-strong" style={{ color: "white" }}>Network Model</strong></h1>
							</FadeInSection>
							<br></br>
						</div>
					</div>
					<div className="row">
						<div align="left" className="col-12 col-xl-5 offset-xl-1 mobile-centered">
							<font size="5" color="#FFA500"><i className="fab fa-bitcoin"></i></font><font size="5" color="white">&nbsp; UTXO Model Accounting</font>
							<p className="pa-p-v1 pa-bright">Transactions represent changes in the UTXO set. Concepts of account and balance are handled by higher abstractions, like wallets, which locate all UTXO’s under control of an address and aggregate them to display an account balance. Already fully compatible with Bitcoin based technologies like Lightning Network. <br></br><Link to="/technology/utxo-and-account-balance-models" className="hc-first-a" >Learn more.</Link></p>
						</div>
						<div align="left" className="col-12 col-xl-5 offset-xl-1 mobile-centered">
							<font size="5" color="#1ad1ff"><i className="fab fa-ethereum"></i></font> <font size="5" color="white">&nbsp; EVM - Ethereum Virtual Machine</font>
							<p className="pa-p-v1 pa-bright">Includes native ERC20/ERC721/ERC1155 support and software platform that developers can use to create decentralized applications (DApps). Managed code has no network access and acts independently: the virtual machine is where all Revo accounts and smart contracts live. <br></br><Link to="/technology/evm" className="hc-first-a">Learn more.</Link></p>
						</div>
						<div align="left" className="col-12 col-xl-5 offset-xl-1 mobile-centered">
							<font size="5" color="#cc99ff"><i className="fab fa-hubspot"></i></font> <font size="5" color="white">&nbsp; AAL - Account Abstraction Layer</font>
							<p className="pa-p-v1 pa-bright">As the name states, abstracts the concept of Accounts away from the implementation, allowing virtual machines to run atop the UTXO model that powers Revo. Initially developed by Qtum core. <br></br><Link to="/technology/account-abstraction-layer" className="hc-first-a" >Learn more.</Link></p>
						</div>
						<div align="left" className="col-12 col-xl-5 offset-xl-1 mobile-centered">
							<font size="5" color="#7CFC00"><i className="fas fa-hdd"></i></font> <font size="5" color="white">&nbsp; Decentralized Storage</font>
							<p className="pa-p-v1 pa-bright">Innovative distribuited solution that guarantees 100% uptime and redundancy. Store unlimited data on Revo's public decentralized storage system or create your own private storage nodes cluster. <br></br><Link to="/technology/decentralized-storage" className="hc-first-a" >Learn more.</Link></p>
						</div>
						<div align="left" className="col-12 col-xl-10 offset-xl-1 mobile-centered">
							<font size="5" color="yellow"><i className="fa fa-puzzle-piece" aria-hidden="true"></i></font> <font size="5" color="white">&nbsp; Compatible Ecosystem</font>
							<p className="pa-p-v1 pa-bright">Revo is layered to fullfill compatibility needs for any kind of software providing secured SSL api to write and call data using web,software and iOT Devices. Open Source software allows Revo to interact with major wallets and create protected transaction completly offline. <br></br></p>
						</div>
						<br></br>

						<br></br>
						<div className="col-12 col-xl-10 offset-xl-1 text-center">
							<FadeInSection>
								<h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"> Our journey just <strong className="hc-second-strong" style={{ color: "white" }}>begun</strong></h1>
							</FadeInSection>
						</div>
						<a href="https://revo.network/images/timeline.PNG"><img className="hc-first-img" src={timeLine} /></a>
						<div className="node-cards-container">{
							dataArr.map(e => {
								return(									
							<div className="node-card-container"  >
							<div style={{ display: `flex` }} className="node-card-divs">
								<img onClick={() => window.open(e.link, '_blank')} className="hc-first-img" style={{ margin: `auto` }} src={e.img} />
							</div>
							<div className="node-card-divs" style={{ textAlign: `center` }}>
								<a href={e.link} style={{  fontSize: `25px`, }}>{e.title}</a>
								<div style={{ textAlign: `justify` }}>
									<span style={{ color: `gray` }} >{e.text}</span> <br></br> <strong>{e.price}</strong>
								</div>
							</div>
							<button onClick={() => window.open(e.link, '_blank')} className="join-as-button" style={{ marginTop: `15px` }}><i style={{marginRight: "10px"}} className="fa fa-shopping-cart"></i>JOIN AS EARLY ADOPTER!</button>
						</div>
								)
							})
						}
						</div>
						<div className="main-product-cards-div" style={{display: "none"}}>{
							dataArr.map(e => {
								return(
									<ProductCards productName={e.title} productPrice={e.price} productImg={e.img} />
								)
							})
						}
						</div>
					</div>
				</div>
			</section>

			<section style={{ height: `fit-content` }} className="pa-hero pa-centered-section pa-image-back pa-bigsize hc-thirst-section" >

				<div className="container">
					{/* Section Header */}
					<div className="row">
						<div className="col-12 col-xl-10 offset-xl-1 text-center">
							<FadeInSection>
								<h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><i className="fas fa-leaf hc-second-i"></i> Only 80 mA (0.4W) to deploy a <strong>Full Node</strong></h1>
							</FadeInSection>
							<FadeInSection>
								<p className="pa-p-v1 pa-bright fadeInDelay03Duration10">Become a part of a truly public blockchain ecosystem at no cost. Build your full node and control it using WiFi on a single Raspberry Pi Zero 2 W. Say goodbye to expensive hardware setups.<br></br><a className="hc-first-a" href="#">Learn more.</a></p>
							</FadeInSection>
						</div>
					</div>
					{/* Services with phone section */}
					<div className="row pa-services text-center">
						<div className="col-12 col-sm-10 offset-sm-1 col-md-4 offset-md-0 offset-lg-0 col-lg-4 order-2 order-md-1 text-center">
							<ul className="pa-services-ul pa-right text-md-right text-left">
								<li>
									<FadeInSection>
										<span className="pa-serv-list-icon fadeInDelay03Duration10"><i className="far fa-laugh-beam" style={{ color: "white" }}></i></span>
										<h3 className="pa-h3-v1 pa-bright fadeInDelay03Duration10">Do it Yourself!</h3>
										<p className="pa-p-v1 pa-bright fadeInDelay03Duration10">Start by simply flashing a pre-built ISO file or compile your own following our Wiki</p>
									</FadeInSection>
								</li>
								<li>
									<FadeInSection>
										<span className="pa-serv-list-icon fadeInDelay06Duration10"><i className="far fa-chart-bar hc-thirst-i" style={{ color: "white" }}></i></span>
										<h3 className="pa-h3-v1 pa-bright fadeInDelay06Duration10">Monitoring</h3>
										<p className="pa-p-v1 pa-bright fadeInDelay06Duration10">Check your stats on web interface locally or publish your node straight on the internet</p>
									</FadeInSection>
								</li>
								<li>
									<FadeInSection>
										<span className="pa-serv-list-icon fadeInDelay10Duration10"><i className="fas fa-volume-mute hc-thirst-i" style={{ color: "white" }}></i></span>
										<h3 className="pa-h3-v1 pa-bright fadeInDelay10Duration10">Noiseless</h3>
										<p className="pa-p-v1 pa-bright fadeInDelay10Duration10">Litte as a credit card, it can fit anywhere</p>
									</FadeInSection>
								</li>
							</ul>
						</div>
						<div className="col-12 col-md-4 order-1 order-md-2">
							<img alt="" className="pa-services-image" src={raspberry} />
						</div>
						<div className="col-12 col-sm-10 offset-sm-1 col-md-4 offset-md-0 offset-lg-0 col-lg-4 order-3 order-md-3 text-center">
							<ul className="pa-services-ul pa-left text-left">
								<li>
									<FadeInSection>
										<span className="pa-serv-list-icon fadeInDelay03Duration10"><i className="fas fa-bolt hc-thirst-i" style={{ color: "white" }}></i></span>
										<h3 className="pa-h3-v1 pa-bright fadeInDelay03Duration10">Power usage</h3>
										<p className="pa-p-v1 pa-bright fadeInDelay03Duration10">Consume less than 1,5$ of electricity per year. Can run days over power bank or micro solar panel</p>
									</FadeInSection>
								</li>
								<li>
									<FadeInSection>
										<span className="pa-serv-list-icon fadeInDelay06Duration10"><i className="fab fa-usb hc-thirst-i" style={{ color: "white" }}></i></span>
										<h3 className="pa-h3-v1 pa-bright fadeInDelay06Duration10">Connectivity</h3>
										<p className="pa-p-v1 pa-bright fadeInDelay06Duration10">It can be powered by any USB 2.0 outlet at your home or office. Supports 2.4 GHz and 5.0 GHz WiFi networks</p>
									</FadeInSection>
								</li>
								<li>
									<FadeInSection>
										<span className="pa-serv-list-icon fadeInDelay10Duration10"><i className="fab fa-pagelines hc-thirst-i" style={{ color: "white" }}></i></span>
										<h3 className="pa-h3-v1 pa-bright fadeInDelay10Duration10">Environmental Friendly</h3>
										<p className="pa-p-v1 pa-bright fadeInDelay10Duration10">Low carbon footprint</p>
									</FadeInSection>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</section>
		</div>
	)
}
