import React from "react";
import { useEffect } from "react";

export default function AAL() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const input_output = `https://${window.location.hostname}/images/technology/input-output.PNG`;
    const operators = `https://${window.location.hostname}/images/technology/operators.PNG`;
    const check_pos = `https://${window.location.hostname}/images/technology/check-pos.PNG`;
    const gas_fee = `https://${window.location.hostname}/images/technology/gas-fee.PNG`;
    const address_conversion = `https://${window.location.hostname}/images/technology/address-conversion.PNG`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - Account Abstraction Layer (AAL||Qtum)`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Account Abstraction Layer (AAL || Qtum)</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Creating a magical bridge to the EVM world</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}}  className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Creating a magical bridge to the EVM world</h1>
                            <p className="pa-p-v1 pa-bright">Qtum was the first blockchain ever to create synergy between the UTXO model and the one based on Account / Balance. Before seeing how, however, it is important to understand the basic logic for the correct functioning of the code of a Smart Contract.</p>
                            <p className="pa-p-v1 pa-bright">If, as mentioned above, for each address we have a UTXO set, how would a Smart Contract interpret and select which coins to use during its execution? And what about internal transactions between contracts which would normally be carried out in the VM by just adjusting the balances of the contract in question? How do you model those in a UTXO model where all spending transactions must be explicitly recorded?</p>
                            <p className="pa-p-v1 pa-bright">When a transaction is sent to the nodes, it is conditional in a language commonly called Bitcoin script, which contains a series of operational codes</p>
                            <img style={{width: '40%', height: '30%'}} src={input_output}/>
                            <p className="pa-p-v1 pa-bright">The cryptocurrency wallet software executes these opcodes on your machine. In this example the instructions tell the software to check the transaction to make sure the public key provided matches the signature of the transaction.</p>
                            <img style={{width: '70%', height: '30%'}} src={operators}/>
                            <p className="pa-p-v1 pa-bright">Qtum’s AAL adds a couple of new opcodes to the Bitcoin opcodes, adding support for smart contracts</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright"><strong>OP_CREATE</strong> — Used to create new smart contracts.</p></li>
								<li><p className="pa-p-v1 pa-bright"><strong>OP_CALL</strong> — Used to execute code inside an existing smart contract.</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>OP_SPEND</strong> — Used to spend the value in a smart contract.</p></li>
							</ul>
                            <p className="pa-p-v1 pa-bright">During the block creation process, the validator’s software will parse the script inside each transaction, and when it comes across transactions using these opcodes, it will set them aside to be processed through the EVM. The EVM contract transactions are then processed into a special “Expected Contract Transaction List” which is executed by validator nodes. These transactions are then executed against the EVM, with the resulting output being converted into a spendable transaction.</p>
                            <p className="pa-p-v1 pa-bright">If, during the execution of a contract, the contract calls another contract with a value, that transaction is also turned into an explicit transaction and attached to the current block.</p>
                            <img style={{width: '70%', height: '30%'}} src={check_pos}/>
                            <p className="pa-p-v1 pa-bright">By making the OP_CREATE, OP_CALL, and OP_SPEND transactions all spendable, we can actually manage the size of the Revo UTXO set fairly well. When a contract self-destructs, the OP_CREATE transaction is spent for example, removing it from the UTXO pool.</p>
                            <p className="pa-p-v1 pa-bright">Another simplification exists for handling when a contract owns more than one UTXO. Instead of trying to pick which coins the contract should use (as earlier versions of Qtum did) any time more than one UTXO exists for a contract, a new tx is created combining and condensing them into one UTXO.</p>
                            <p className="pa-p-v1 pa-bright">A problem also exists concerning refunding gas fees in the UTXO model. Gas Model for Ethereum works by overestimating the cost of contract execution and after execution refunding the remaining amount by crediting it back to the spending account balance. This is relatively easily done in Accounts model through internal transactions but in the UTXO model it is impossible for a validator to partially refund a fee for transactions that don’t use up all the gas. It must also be possible to roll back transactions that run out of gas while still crediting the spent gas to validators.</p>
                            <p className="pa-p-v1 pa-bright">Refunding gas fees in Revo works by creating new outputs as part of the coinbase transaction for that block. All of the input gas is consumed and given to the validator, and the validator must include outputs in the coinbase transaction that credit the transaction senders with the gas refunds. These refunds are enforced using a new block validation consensus rule. Otherwise, it would be possible for validators to not return any gas at all.</p>
                            <p className="pa-p-v1 pa-bright">Another problem exists in how transactions actually specify and send gas fees. In the UTXO model, gas and transaction fees are both combined and sent together so how can we separate out what is the fee to relay the transaction and how much to spend on gas and how much to refund compared to how much was used. This is done through a new simple fee model:</p>
                            <img style={{width: '40%', height: '30%'}} src={gas_fee}/>
                            <p className="pa-p-v1 pa-bright">After completing the contract execution, the remainder of the gas fee must be returned to the given gas return script by adding an output to the coinbase transaction the validators use to retrieve their block reward.</p>
                            <h1 id="address-conversion" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Address conversion</h1>
                            <img style={{width: '70%', height: '30%'}} src={address_conversion}/>
                            <p className="pa-p-v1 pa-bright">To physically maintain compatibility with ethereum-based addresses, each Revo address must be converted from base68 to hexadecimal. This conversion procedure is done via the <strong>gethexfromaddress</strong> and <strong>fromhexaddress</strong> commands.</p>
                            <h1 id="connectors-metamask-walletconnect" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Connectors (Metamask, WalletConnect..)</h1>
                            <p className="pa-p-v1 pa-bright">The backward compatibility of hexadecimal addresses may seem trivial, but it is really essential to allow connection gateways to work natively with REVO. MetaMask allows users to store and manage account keys, broadcast transactions, send and receive cryptocurrencies and tokens, and securely connect to decentralized applications through a compatible web browser or the mobile app's built-in browser.</p>
                            <p className="pa-p-v1 pa-bright">WalletConnect is another example of open source protocol for connecting decentralized applications to cryptocurrency wallets with QR code scanning or deep linking. These connection gateways are the keystone that will allow all users of the world, even the less experienced, to interact with the blockchain world in a direct, secure and non-custodial way, a real bridge for mass adoption.</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
