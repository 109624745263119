import React from "react";
import { useEffect } from "react";

export default function DDS() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - Decentralized Domain System`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Decentralized Domain System</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Self sustained DDS</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left"  style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Decentralized Domain System</h1>
                            <p className="pa-p-v1 pa-bright">The domain name system (DNS) is a part of the internet’s plumbing users may not often think about unless it stops working. It’s a different story for web hosting providers and site owners; they deal with DNS directly because they depend on it to translate human-friendly web addresses into machine-friendly IP addresses. Every website has a domain name registered with a domain name registrar.</p>
                            <p className="pa-p-v1 pa-bright">The registrars work with registry operators, which manage registries—databases of domain and registrant information— and top-level DNS servers. At the root of the tree is IANA, the Internet Assigned Numbers Authority. IANA is administered by ICANN, the Internet Corporation for Assigned Names and Numbers. It maintains the root zone files and delegates domain name management of top-level domains like .com to registry operators. It’s a complicated hierarchy, and for the most part, it works well. There are, however, weaknesses with this system, which was invented in 1983, almost a decade before Tim Berners-Lee published the first website.</p>
                            <p className="pa-p-v1 pa-bright">When top-level DNS servers go down, so do large chunks of the web, which happens with alarming regularity. The system depends on the trustworthiness of registrars and registry operators, and it’s a weak point criminals and censorious governments can attack.</p>
                            <p className="pa-p-v1 pa-bright">A decentralized domain name system is a possible solution, and blockchain-based DNS looks like the most promising candidate in 2022. Decentralized DNS isn’t in a position to take over from the centralized DNS we’re all familiar with just yet. But it’s worth understanding how it works and the role it could play in bringing resilience and independence to one of the most centralized and hierarchical aspects of the internet’s infrastructure.</p>
                            <p className="pa-p-v1 pa-bright">Decentralized domain name projects aim to remove DNS’s dependence on ICANN and the registries. Blockchains, the technology Bitcoin is based on, are one way to achieve this. A blockchain is a distributed public ledger—a database duplicated across many computers. Blockchains are organized into sequential blocks of data where each block is connected to the previous and subsequent blocks.</p>
                            <p className="pa-p-v1 pa-bright">In the case of Bitcoin, the blockchain acts as a decentralized record of transactions, but it’s easy to see how this could replace some DNS functionality. Instead of registering a domain name with a registrar, you would register it on a blockchain. Because blocks are ordered and the blockchain is distributed, no one can register a name twice, just as they can’t spend the same bitcoin twice.</p>
                            <p className="pa-p-v1 pa-bright">Decentralized DNS systems don’t aim to replace every part of the domain name system. Instead, they act as alternate roots. Much of DNS is already decentralized. Anyone can use cPanel, Plesk or ISPConfig to set up an authoritative domain name server for their own domains. However, the registries are centralized, and one organization manages the root zone file. That’s why you have to register domain names with a registrar; they’re agents of the central authority. It’s also why you pay regular renewal fees. A centralized global naming system is expensive to run.</p>
                            <p className="pa-p-v1 pa-bright">We’re in the early days of decentralized DNS, and there are several active projects with similar aims. Namecoin was one of the first. It was released in 2011 but hasn’t seen widespread adoption. Unstoppable Domains is another entry in the field. Handshake is an interesting new contender which bills itself as a “decentralized naming and certificate authority.”</p>
                            <p className="pa-p-v1 pa-bright">Like the other decentralized naming systems, Handshake is a blockchain-based root zone alternative with an interesting solution to a problem that plagued earlier efforts—domain squatting. It was easy to register any domain, so squatters gobbled up tens of thousands they never intended to use. Handshake, in contrast, uses an auction to allocate domains.</p>
                            <p className="pa-p-v1 pa-bright">Like Bitcoin, the Handshake blockchain uses a proof-of-work “mining” system to add new blocks, generating a coin called HNS. HNS coins are used to bid in Vickery auctions for toplevel domains. For the same reason, Handshake-registered domains do have associated renewal fees. Decentralization is the primary motivation behind blockchain-based domains, but there are other potential benefits:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright"><strong>Anonymous</strong> — Just as Bitcoin transactions are anonymous, so are blockchain-registered domains.</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>Censorship resistant</strong> — A centralized DNS system is vulnerable to censorship. The operating authority could be influenced to remove registered domains from the registry. Blockchains are distributed, and no single entity controls them, making censorship much harder.</p></li>
                                <li><p className="pa-p-v1 pa-bright"><strong>Secure</strong> — You might have noticed that Handshake also calls itself a “decentralized certificate authority.” Today, we rely on centralized certificate authorities and SSL certificates to verify the identity of sites we connect to. Because they are practically tamper-proof, blockchains can perform the same function. Additionally, CAs can be hacked or subverted, something that’s much harder to achieve on a distributed blockchain.</p></li>
                            </ul>
                            <p className="pa-p-v1 pa-bright">The use of ERC721 Tokens in the form of digital assets, including for the proof-of-possession purposes in real life is being actively discussed today. However, smart contracts work much better with digital entities (especially those existing only within a smart contract environment). In this case it can really manage them with no need to have an intermediate layer to manage real world assets from the blockchain and control thereof.</p>
                            <p className="pa-p-v1 pa-bright">Domain name is an unconditional value of the digital world. Domain names that sound good and give people a strong idea of what activity a website is about, can be sold or bought for several hundred of thousand, and even million dollars. The DNS itself is in fact a type of address book, a registry which keeps records of domain names and servers related thereto. And, as long as blockchain is a distributed registry, it is very well suited to a fully decentralized DNS, so no supervision on the part of zone owners and regulators is required. In turn, being the completely digital tradable assets, domain names are ideally suited to be ERC721 Tokens: they are unique (each domain name is unique), have their own value and can be sold or transferred from one owner to another.</p>
                            <p className="pa-p-v1 pa-bright">Here is how it works on Revo:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">When registering a domain name on the Revo blockchain, you receive an ERC721 Token identified by the hash of the domain name (converted into a uint256). The token contains the owner's wallet address and the textual domain name</p></li>
                                <li><p className="pa-p-v1 pa-bright">The smart contract system also enables configuration of the addresses of the domain servers</p></li>
                                <li><p className="pa-p-v1 pa-bright">Once received, you can store the token and accordingly use the domain name, transfer it to another person or put it up for sale on any exchange that works with ERC721 Tokens.</p></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
