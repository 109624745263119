import React from 'react';
import '../styles/Home.css'
import { useEffect, useState } from 'react';
import Header from './Header';
import ProfilesContent from './content/ProfilesContent';
import Footer from './Footer';
import { useDispatch, useSelector } from 'react-redux';


export default function Profiles() {    
    const dispatch = useDispatch();
    const homeLoaded = useSelector((state) => state.homeLoaded);


    useEffect(async () => {        
        document.title= `Revo | Profiles`
        if (window.location.hostname.slice(0, 3) == 'www') {
            window.location.href = 'https://revo.network/';
        }
    }, []);
    
    

    return (
        <div className='div-main-home'>
        <div style={{opacity: `0`, display: `none`}} className="pa-loader">
            <section className="warper"><div className="spinn" style={{opacity: `0.24`}}><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div><div className="preloader"><p className="hello"></p><div id="preloader"><div id="loader"></div></div></div></section>
        </div>
            <Header />
            <ProfilesContent />
            <Footer />
        </div>
    )
}
