import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import Footer from "./Footer";
import Header from "./Header";
import '../styles/ApiDocs.css';
import AAL from "./techdocs/AAL";
import DDS from "./techdocs/DDS";
import EVM from "./techdocs/EVM";
import LightNet from "./techdocs/LightNet";
import PoS from "./techdocs/PoS";
import Sidechains from "./techdocs/Sidechains";
import UTXO from "./techdocs/UTXO";
import VM from "./techdocs/VM";
import DS from "./techdocs/DS";

export default function TechDocs() {

    const navigate = useNavigate();

    useEffect(() => {
        let target = window.location.pathname;
        let address = ['account-abstraction-layer', 'decentralized-domain-system', 'evm', 'lightning-network', 'proof-of-stake', 'sidechains', 'utxo-and-account-balance-models', 'x86-virtual-machine', 'decentralized-storage'];
        if (target.length <= 12) {
            navigate('/');
            window.location.reload();
        }else if(!address.includes(target.slice(12))){
            navigate('/');
            window.location.reload();
        }
    },[])
    
    
    const [ loadingState, setLoadingState ] = useState(false);
    
 	setTimeout(function() {
        setLoadingState(true);
 	}, 1000);


    return (
        <div>
        <div style={loadingState ? {opacity: `0`, display: `none`} : {}} className="pa-loader">
            <section className="warper"><div className="spinn" style={{opacity: `0.24`}}><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div><div className="preloader"><p className="hello"></p><div id="preloader"><div id="loader"></div></div></div></section>
        </div>
            <Header />
            {
                window.location.pathname.slice(12) == 'account-abstraction-layer' && <AAL />
            }{
                window.location.pathname.slice(12) == 'decentralized-domain-system' && <DDS />
            }{
                window.location.pathname.slice(12) == 'evm' && <EVM />
            }{
                window.location.pathname.slice(12) == 'lightning-network' && <LightNet />
            }{
                window.location.pathname.slice(12) == 'proof-of-stake' && <PoS />
            }{
                window.location.pathname.slice(12) == 'sidechains' && <Sidechains />
            }{
                window.location.pathname.slice(12) == 'utxo-and-account-balance-models' && <UTXO />
            }{
                window.location.pathname.slice(12) == 'x86-virtual-machine' && <VM />
            }{
                window.location.pathname.slice(12) == 'decentralized-storage' && <DS />
            }
            <Footer />
        </div>
    )
}