import React from "react";
import { useEffect } from "react";

export default function Blockchain() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    
    useEffect(() =>{
        document.title= `Revo | API - Blockchain`
      }, []);     

    return (
        <div style={{minHeight: `100vh`}}>
            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Blockchain</h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Basic blockchain informations.</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Blockchain Information</h1>
                            <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                            <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>

                            <font color="white" style={{fontSize: `26px !important`}}>Table of contents</font><br></br>
                        <ul style={{color: `white`}}>
                            <li><b><a style={{color: `white`}} href="#blockchain">Blockchain</a></b>
                                <ul>
                                    <li><a style={{color: `white`}} href="#blockchain-information">Blockchain Information</a></li>
                                    <li><a style={{color: `white`}} href="#blockchain-supply">Supply</a></li>
                                    <li><a style={{color: `white`}} href="#blockchain-total-max-supply">Total Max Supply</a></li>
                                </ul>
                            </li>
                        </ul>

                        <br></br>
                        <font id="blockchain-information" color="white">Blockchain info</font>
                        <table style={{width: `100%`}}>
                            <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                <td ><font className="ad-first-font">/info</font></td>
                            </tr>
                            <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                <td>
                                    <p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Used to display real time blockchain status. Get current height, staking weight, feerate and dgp protocol informations.</p>
                                    <font color="#1fcecb">Examples</font><br></br>
                                    <a className="exampleapi" href="https://api.revo.network/info" target="_blank">https://api.revo.network/info</a>
                                </td>
                            </tr>
                        </table>
                        <br></br>
                        <font id="blockchain-supply" color="white">Coin supply</font>
                        <table style={{width: `100%`}}>
                            <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                <td ><font className="ad-first-font">/supply</font></td>
                            </tr>
                            <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (int) Response</font></td>
                                <td>
                                    <p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display current RVO current supply</p>
                                    <font color="#1fcecb">Examples</font><br></br>
                                    <a className="exampleapi" href="https://api.revo.network/supply" target="_blank">https://api.revo.network/supply</a>
                                </td>
                            </tr>
                        </table>
                        <br></br>
                        <font id="blockchain-total-max-supply" color="white">Max supply</font>
                        <table style={{width: `100%`}}>
                            <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                <td ><font className="ad-first-font">/total-max-supply</font></td>
                            </tr>
                            <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (double) Response</font></td>
                                <td>
                                    <p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display RVO maximum lifetime COIN supply</p>
                                    <font color="#1fcecb">Examples</font><br></br>
                                    <a className="exampleapi" href="https://api.revo.network/total-max-supply" target="_blank">https://api.revo.network/total-max-supply</a>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
                </div>
            </section>
        </div>
    )
}
