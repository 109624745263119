import React from "react";

export default function TechnologyContent() {
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    return (
        <div style={{ minHeight: `100vh` }}>
            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Revo Technical Characteristics</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Enterprise grade, untamperable sidechains</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
    <div className="container">
        {/* Section Header */}
        <div className="row">
            <div align="left" className="col-12 col-xl-10 offset-xl-1">

                <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Enterprise grade with untamperable Sidechains</h1>
                <p className="main-text-color">As already mentioned above, Revo is a public blockchain developed starting from the Qtum source code that immediately set itself quite ambitious goals. What is shown in the previous analysis is that medium and high level Companies need something very reliable, stable and predictable. For example, a Company that has decided to monitor its supply chain through the use of blockchain technology cannot afford to sustain large market variations in the price of the currency. This is because a system that today turns out to have a certain degree of efficiency, could in the future lead to a sudden change of parameters and values due to market conditions. As we all know, companies base their operations on the basis of a business plan and actions and corrections are implemented according to what is defined by the same: if at any time the cards on the table change, it could be a huge operational (as well as economic) problem.</p>
				<p className="main-text-color">Revo is a decentralized blockchain, but this does not mean that all applications linked to it must be the same. Depending on the degree of complexity and volumes that a DApp requires, it may be necessary to build a private blockchain to meet its specific needs (speed, zero latency, ability to save huge amounts of data, transactions per second, etc.). However, it is necessary to understand that a private blockchain, by its nature, will never be able to satisfy the requirements of transparency, security and immutability, as it is in fact centralized and governed by a single entity.</p>
				<p className="main-text-color">In fact, for the latter problem, Revo provides a framework capable of making operational PoA sidechains interconnected with REVO, the primary blockchain. Thanks to this new type of protocol, the centralized sidechains will notarize their existence on the primary public blockchain (PoI - Proof of Immutabilty) block by block, avoiding the possibility of future tampering.</p>
                				
                <font color="white" className="pc-first-font">Table of contents</font><br></br>
                <ul className="pc-first-ul">
                  <li><b><a  href="/technology/utxo-and-account-balance-models">UTXO and Account/Balance models</a></b>
                  </li>
                  <li><b><a  href="/technology/account-abstraction-layer">Account Abstraction Layer (AAL||Qtum)</a></b>
                    <ul>
                      <li><a  href="/technology/account-abstraction-layer#address-conversion">Address conversion</a></li>
                      <li><a  href="/technology/account-abstraction-layer#connectors-metamask-walletconnect">Connectors |Metamask, WalletConnect..|</a></li>
                    </ul>
                  </li>
                  <li><b><a  href="/technology/evm">Ethereum EVM</a></b>
                    <ul>
                      <li><a  href="/technology/evm#solitidy">Solidity</a></li>
                      <li><a  href="/technology/evm#ide-and-public-compilers">IDE and public Compilers</a></li>
                    </ul>
                  </li>
                   <li><b><a  href="/technology/x86-virtual-machine">x86 Virtual Machine</a></b>
                  </li>
                  <li><b><a  href="/technology/lightning-network">Lightning Network</a></b>
                    <ul>
                      <li><a  href="/technology/lightning-network#compatibility">Compatibility</a></li>
                    </ul>
                  </li>
                  <li><b><a  href="/technology/proof-of-stake">Proof Of Stake</a></b>
                    <ul>
                      <li><a  href="/technology/proof-of-stake#offline-staking">Offline Staking (Contract Delegation)</a></li>
                    </ul>
                  </li>
                  <li><b><a  href="/technology/sidechains">SideChains (Enterprise)</a></b>
                    <ul>
                      <li><a  href="/technology/sidechains#model">Sidechain model</a></li>
                      <li><a  href="/technology/sidechains#network-consensus">Network and consensus protocol</a></li>
                      <li><a  href="/technology/sidechains#scalability-tps">Speed scalability and TPS</a></li>
                    </ul>
                  </li>
                  <li><b><a  href="/technology/decentralized-domain-system">Decentralized Domain System</a></b></li>
                  <li><b><a  href="/technology/decentralized-storage">Decentralized Storage</a></b>
                    <ul>
                      <li><a  href="/technology/decentralized-storage#model-technology">Model Technology</a></li>
                      <li><a  href="/technology/decentralized-storage#public-personal-storage-pools">Public and Personal storage pools</a></li>
                      <li><a  href="/technology/decentralized-storage#reward-system">Reward System</a></li>
                    </ul>
                  </li>
                </ul>
            </div>
        </div>
    </div>
</section>
        </div>
    )
}