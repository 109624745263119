import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import PublicContent from "./content/PublicContent";
import { useEffect } from "react";


export default function PublicApi () {

    useEffect(() =>{
        document.title= `Revo | API`
      }, []);     

    return(
        <div>
            <Header />
            <PublicContent/>
            <Footer />    
        </div>
    )
}