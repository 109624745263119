import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ArticleContent from "./content/ArticleContent";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getArticles } from "../actions";


export default function Article () {
    const dispatch = useDispatch();
    const articles = useSelector((state) => state.articles);

    const [contentOrder, setContentOrder] = useState(false);

    useEffect(() =>{
        if(!articles.length){
            dispatch(getArticles());
            setContentOrder(true);
        }
      }, [dispatch]);     

      
    const [ sortedArticles, setSortedArticles ] = useState(false);


    function sortArticles (){
        let orderedArticles = articles.sort(function (a, b) {
            if (a.id < b.id) {
                return 1;
            }
            if (a.id > b.id) {
                return -1;
            }
            // a must be equal to b
            return 0;
        });
        setSortedArticles(orderedArticles);
    }

    articles.length && !sortedArticles && sortArticles();


    return(
        <div>
        <div style={articles.length ? {opacity: `0`, display: `none`} : {}} className="pa-loader">
            <section className="warper"><div className="spinn" style={{opacity: `0.24`}}><i></i><i></i><i></i><i></i><i></i><i></i><i></i></div><div className="preloader"><p className="hello"></p><div id="preloader"><div id="loader"></div></div></div></section>
        </div>
            <Header />
            {articles.length && sortedArticles && <ArticleContent content={sortedArticles}/>}
            <Footer />    
        </div>
    )
}