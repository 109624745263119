import React from "react";
import '../../styles/PublicContent.css';

export default function PublicContent (){
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    return (
        <div style={{minHeight: `100vh`}}>
        <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>
    
            
    
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1 text-center">
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Onchain <strong>Public API</strong> docs</h1>
                        <p className="pa-p-v1 pa-white fadeInDelay03Duration10">This page offers a full in depth documentation of current open blockchain api. Get blocks, address, transactions and call smart contracts in the easiest way. The possibility to explore fullchain data publicly at any time with no limitations is provided via HTTPS GET/POST endpoints</p>
                        </div>
                </div>
            </div>
    
        </section>
        
        <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div align="left" className="col-12 col-xl-10 offset-xl-1">
        
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">BETA API Endpoints</h1>
                        <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                        <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>
                        
                        <font color="white" className="pc-first-font">Table of contents</font><br></br>
                        <ul className="pc-first-ul">
                          <li><b><a  href="/docs-api/blockchain#blockchain">Blockchain</a></b>
                            <ul>
                              <li><a  href="/docs-api/blockchain#blockchain-information">Blockchain Information</a></li>
                              <li><a  href="/docs-api/blockchain#blockchain-supply">Supply</a></li>
                              <li><a  href="/docs-api/blockchain#blockchain-total-max-supply">Total Max Supply</a></li>
                            </ul>
                          </li>
                          <li><b><a  href="/docs-api/block#block">Block</a></b>
                            <ul>
                              <li><a  href="/docs-api/block#block-info">Block info</a></li>
                              <li><a  href="/docs-api/block#blocks-of-date">Blocks of Date</a></li>
                              <li><a  href="/docs-api/block#recent-blocks">Recent Blocks</a></li>
                            </ul>
                          </li>
                          <li><b><a  href="/docs-api/transaction#transaction">Transaction</a></b>
                            <ul>
                              <li><a  href="/docs-api/transaction#transaction-information">Single Transaction Information</a></li>
                              <li><a  href="/docs-api/transaction#raw-transaction">Raw Transaction</a></li>
                              <li><a  href="/docs-api/transaction#send-raw-transaction">Send Raw Transaction</a></li>
                            </ul>
                          </li>
                           <li><b><a  href="/docs-api/address#address">Address</a></b>
                            <ul>
                              <li><a  href="/docs-api/address#address-information">Address information</a></li>
                              <li><a  href="/docs-api/address#address-balance">Address Balance</a></li>
                              <li><a  href="/docs-api/address#address-transactions">Address Transactions</a></li>
                              <li><a  href="/docs-api/address#address-basic-transactions">Address Basic Transactions</a></li>
                              <li><a  href="/docs-api/address#address-contract-transactions">Address Contract Transactions</a></li>
                              <li><a  href="/docs-api/address#address-erc20-token-transactions">Address ERC20 Token Transactions</a></li>
                              <li><a  href="/docs-api/address#address-utxo-list">Address UTXO List</a></li>
                              <li><a  href="/docs-api/address#address-balance-history">Address Balance History</a></li>
                              <li><a  href="/docs-api/address#address-erc20-balance-history">Address ERC20 Balance History</a></li>
                            </ul>
                          </li>
                          <li><b><a  href="/docs-api/contract#contract">Contract</a></b>
                            <ul>
                              <li><a  href="/docs-api/contract#contract-information">Contract Information</a></li>
                              <li><a  href="/docs-api/contract#contract transaction">Contract Transactions</a></li>
                              <li><a  href="/docs-api/contract#contract-basic-transactions">Contract Basic Transactions</a></li>
                              <li><a  href="/docs-api/contract#call-contract">Call Contract</a></li>
                              <li><a  href="/docs-api/contract#search-logs">Search Logs</a></li>
                            </ul>
                          </li>
                          <li><b><a  href="/docs-api/erc20#erc20">ERC20</a></b>
                            <ul>
                              <li><a  href="/docs-api/erc20#erc20-list">ERC20 list</a></li>
                              <li><a  href="/docs-api/erc20#erc20-transaction-list">ERC20 Transaction list</a></li>
                            </ul>
                          </li>
                          <li><b><a  href="/docs-api/erc721#erc721">ERC721</a></b>
                            <ul>
                              <li><a  href="/docs-api/erc721#erc721-list">ERC721 list</a></li>
                            </ul>
                          </li>
                          <li><b><a  href="/docs-api/pagination-parameters#pagination-parameters">Pagination Parameters</a></b></li>
                          <li><b><a  href="/docs-api/block-timestamp-filters#filters">Block / Timestamp Filter Parameters</a></b></li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}