import React from "react";
import FadeInSection from "../addons/FadeInSection";

export default function NewsContent() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const logoSolhea = `https://${window.location.hostname}/images/logo-solhea.svg`;
    const bureau = `https://${window.location.hostname}/images/bureau.png`;
    const kubesaiLogo = `https://${window.location.hostname}/images/kubesail-logo.svg`;
    const revolutionBlock = `https://${window.location.hostname}/images/revolutionchain.svg`;

    return (
        <div style={{ minHeight: `100vh` }}>
            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Partners</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Proudly working with</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id="srvices" style={{backgroundColor: `transparent`}} className="pa-what-we-do pa-standard-section text-center hc-four-section">

                <div className="container">
                    {/* Section Header */}
                    <FadeInSection>
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10 wow fadeIn hc-first-h1" data-wow-duration="1.0s" data-wow-delay="0.0s"></h1>
                    </FadeInSection>
                    <div className="row">
                        <br></br>
                        <div align="center" className="col-12 col-xl-2">
                            <a href="https://www.solhea.com" target="_blank"><img className="hc-second-img" src={logoSolhea} /></a>
                        </div>
                        <div align="center" className="col-12 col-xl-2 offset-xl-1">
                            <a href="https://www.bureauveritas.it/" target="_blank"><img className="hc-second-img" src={bureau} /></a>
                        </div>
                        <div align="center" className="col-12 col-xl-2 offset-xl-1">
                            <a href="https://kubesail.com" target="_blank"><img className="hc-thirst-img" src={kubesaiLogo} /></a>
                        </div>
                        <div align="center" className="col-12 col-xl-2 offset-xl-1">
                            <a href="https://revolutionchain.it" target="_blank"><img className="hc-second-img" src={revolutionBlock} /></a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}