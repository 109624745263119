import React from "react";
import { useEffect } from "react";

export default function VM() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const x86_evm = `https://${window.location.hostname}/images/technology/x86-evm.PNG`;
    
    useEffect(() =>{
        document.title= `Revo | Technology - x86 Virtual Machine`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>x86 Virtual Machine</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Multiple integrations of innovative technologies</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">x86 Virtual Machine</h1>
                            <p className="pa-p-v1 pa-bright">The Qtum team has created an x86 Virtual machine (VM) for executing smart contracts in a variety of programming languages. This VM operates very similarly to the existing Ethereum VM, with some notable enhancements:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright">In the Ethereum VM, all smart contracts must be written in Solidity and compiled down to byte code executed on the EVM. In this x86 VM, developers can write their smart contracts in any language that supports a JIT compiler, including C++, Java, Python, and other languages widely used in application development. This gives dApp developers much more flexibility in terms of smart contract development.</p></li>
								<li><p className="pa-p-v1 pa-bright">In addition to supporting the existing gas model on common EVM (where users pay for each operation they execute in a smart contract), X86 has also added two new pricing models: fixed-fee per transaction and fee per transaction.</p></li>
                                <li><p className="pa-p-v1 pa-bright">It supports x86 registers and memory operations through a unified 16-bit instruction encoding scheme that significantly reduces gas costs in common cases compared to the Ethereum VM. This will allow developers to execute more operations per transaction and increase throughput on the Qtum blockchain.</p></li>
							</ul>
                            <img style={{width: '40%', height: '30%'}} src={x86_evm}/>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
