import React from 'react';
import { useEffect } from 'react';
import ComingSoon from './ComingSoon';


export default function Training() {
    


    useEffect(async () => {        
        document.title= `Revo | Coming Soon..`
        if (window.location.hostname.slice(0, 3) == 'www') {
            window.location.href = 'https://revo.network/';
        }
    }, []);

    return (
            <ComingSoon />
    )
}
