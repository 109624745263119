import React from "react";
import { useEffect } from "react";

export default function Contract() {
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
  
  useEffect(() =>{
    document.title= `Revo | API - Contract`
  }, []);     

    return (
        <div style={{minHeight: `100vh`}}>
        <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>
    
            
    
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1 text-center">
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Contract</h1>
                        <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Get information data of specific RVO contract. Balances, transactions, list. Call data and inspect arrays.</p>
                        
                        </div>
                </div>
            </div>
    
        </section>
        
        <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div align="left" className="col-12 col-xl-10 offset-xl-1">
        
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Contract</h1>					
                        <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                        <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>
                        
                        <font color="white" style={{fontSize: `26px !important`}}>Table of contents</font><br></br>
                        <ul style={{color: `white`}}>
                          <li><b><a style={{color: `white`}} href="#contract">Contract</a></b>
                            <ul>
                              <li><a style={{color: `white`}} href="#contract-information">Contract Information</a></li>
                              <li><a style={{color: `white`}} href="#contract transaction">Contract Transactions</a></li>
                              <li><a style={{color: `white`}} href="#contract-basic-transactions">Contract Basic Transactions</a></li>
                              <li><a style={{color: `white`}} href="#call-contract">Call Contract</a></li>
                              <li><a style={{color: `white`}} href="#search-logs">Search Logs</a></li>
                            </ul>
                          </li>					  
                        </ul>
                        
                        <br></br>

                        <font id="contract"color="white" style={{fontSize: `26px !important`}}>Contract</font>
                        <p className="pa-p-v1 pa-bright">Get information data of specific RVO contract. Balances, transactions, list. Call data and inspect arrays.</p>					
                        <font id="contract-information" color="white">Contract Information</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">/contract/:contract</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Returns contract basic informations.</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/contract/0000000000000000000000000000000000000080/" target="_blank">https://api.revo.network/contract/0000000000...00000000080/</a><br></br>
                            </td>
                          </tr>
                        </table>
                        <br></br>
                        <font id="contract-transactions" color="white">Contract Transactions</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">contract/:contract/txs</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Returns contract transactions. Accepts pagination parameters and block/timestamp filter parameters. Can be reversed.</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/contract/0000000000000000000000000000000000000080/txs" target="_blank">https://api.revo.network/contract/0000000000...00000000080/txs</a><br></br>
                            </td>
                          </tr>
                        </table>
                        <br></br>
                        <font id="contract-basic-transactions" color="white">Contract Basic Transactions</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">contract/:contract/basic-txs</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Returns list of transactions the contract is called. Accepts pagination parameters and block/timestamp filter parameters. Can be reversed.</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/contract/0000000000000000000000000000000000000080/basic-txs" target="_blank">https://api.revo.network/contract/0000000000...00000000080/basic-txs</a><br></br>
                            </td>
                          </tr>
                        </table>
                        <br></br>
                        <font id="call-contract" color="white">Call Contract</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">/contract/:contract/call</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Request shall be performed using data parameter. Sender string is optional. Data should be hexadecimal</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/contract/0000000000000000000000000000000000000080/call?data=e21f37ce" target="_blank">https://api.revo.network/contract/0000000000...00000000080/call?data=e21f37ce</a><br></br>
                            </td>
                          </tr>
                        </table>
                        <br></br>
                        <font id="search-logs" color="white">Search Logs</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">/searchlogs</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Can be used to search latest 100 blockchain logs on transactions. Accepts pagination parameters and block/timestamp filter parameters. Contract filter on each log topic available</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/searchlogs?=contract=0000000000000000000000000000000000000080" target="_blank">https://api.revo.network/searchlogs?=contract=0000000000...00000000080</a><br></br>
                            </td>
                          </tr>
                        </table>	
                    </div>
                </div>
            </div>
        </section>
        
        </div>
    )
}
