import React from "react";
import { useEffect } from "react";

export default function Erc20(){
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

  
  useEffect(() =>{
    document.title= `Revo | API - Erc20`
  }, []);     

    return (
        <div style={{minHeight: `100vh`}}>
        <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>
    
            
    
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1 text-center">
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">ERC20</h1>
                        <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Get information data of ERC20 contracts. Balances, transactions, list. Call data and inspect arrays.</p>
                        
                        </div>
                </div>
            </div>
    
        </section>
        
        <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div align="left" className="col-12 col-xl-10 offset-xl-1">
        
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">ERC20</h1>					
                        <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                        <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>
                        
                        <font color="white" style={{fontSize: `26px !important`}}>Table of contents</font><br></br>
                        <ul style={{color: `white`}}>
                          <li><b><a style={{color: `white`}} href="#erc20">ERC20</a></b>
                            <ul>
                              <li><a style={{color: `white`}} href="#erc20-list">ERC20 list</a></li>
                              <li><a style={{color: `white`}} href="#erc20-transaction-list">ERC20 Transaction list</a></li>
                            </ul>
                          </li>					  
                        </ul>
                        
                        <br></br>
                                        
                        <font id="erc20"color="white" style={{fontSize: `26px !important`}}>ERC20</font>
                        <p className="pa-p-v1 pa-bright">Get information data of ERC20 contracts. Balances, transactions, list. Call data and inspect arrays.</p>					
                        <font id="erc20-list" color="white">ERC20 list</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">/erc20</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">List all ERC20 tokens order by transfer transactions count.Accepts pagination parameters and block/timestamp filter parameters.</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/erc20" target="_blank">https://api.revo.network/contract/erc20</a><br></br>
                            </td>
                          </tr>
                        </table>
                        <br></br>
                        <font id="erc20-transaction-list" color="white">ERC20 Transaction list</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">/erc20/:token/txs</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">List all ERC20 token transactions of specific ERC20 token contract. Accepts pagination parameters.</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/erc20/a136274710e91592eaf0e404ed24f87a83c31762/txs" target="_blank">https://api.revo.network/erc20/a13627471...a83c31762/txs</a><br></br>
                            </td>
                          </tr>
                        </table>	
                    </div>
                </div>
            </div>
        </section>       
    

        </div>
    )
}
