import React from "react";
import { useEffect } from "react";

export default function BlockTimestampFilter() {
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

  
  useEffect(() =>{
    document.title= `Revo | API - Block Timestamp Filter`
  }, []);     

    return (
        <div style={{minHeight: `100vh`}}>
        <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>
    
            
    
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1 text-center">
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Block / Timestamp Filter Parameters</h1>
                        <p className="pa-p-v1 pa-white fadeInDelay03Duration10">These params are available in some transaction list queries.</p>
                        
                        </div>
                </div>
            </div>
    
        </section>
        
        <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div align="left" className="col-12 col-xl-10 offset-xl-1">
        
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Block / Timestamp Filter Parameters</h1>					
                        <p className="pa-p-v1 pa-bright">These params are available in some transaction list queries, records are picked only when <br></br> <pre style={{marginTop: `-20`, color: `white`}}>fromBlock ← blockHeight ← toBlock, fromTime ← blockTimestamp ← toTime </pre></p>
                                      
                        <table style={{width: `100%`}}>
                          <tr>
                            <td width="150px"><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> Name</font></td>
                            <td width="150px"><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> Type</font></td>
                            <td width="150px"><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> Description</font></td>
                          </tr>
                          <tr>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">fromBlock</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Number (optional)</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Search blocks from height</p></td>
                          </tr>
                          <tr>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">toBlock</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Number (optional)</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Search blocks until height</p></td>
                          </tr>
                          <tr>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">fromTime</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">ISO 8601 Date String (optional)</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Search blocks from timestamp</p></td>
                          </tr>
                          <tr>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">toTime</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">ISO 8601 Date String (optional)</p></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Search blocks until timestamp</p></td>
                          </tr>
                        </table>					
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
}