import React from "react";


export default function Footer() {
    let date = new Date();
    return (
        <div >
            {/* Footer
	================================================== */}
            <footer style={window.location.pathname.length !== 1 ? {boxShadow: `inset 0px 20px 15px rgb(0,0,0)` } : {}}>
                <div className="container" >
                    <div className="row" style={{paddingLeft: "0", paddingRight: "0"}}>
                        <div className="col-12 text-center my-2 pt-4 pb-2">
                            <p> {date.getFullYear()} - Revo Technologies<font color="green"><br></br><i className="fa fa-leaf" aria-hidden="true"></i> energy saving protocol</font></p>
                            <a href="#"><i className="fab fa-github-alt"></i></a>&nbsp;
                            <a target="_blank" href="https://twitter.com/RevoBlockchain"><i className="fab fa-twitter"></i></a>&nbsp;
                            <a target="_blank" href="https://www.linkedin.com/company/revo-blockchain-technologies"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
                <div className="pa-back-to-top-wrap">
                    <a className="pa-back-to-top" href="#top"><span className="pa-back-to-top"></span></a>
                </div>
            </footer>


            {/* Helper div for inserting before scripts
	================================================== */}
            <div className="pa-body-end"></div>
        </div>
    )
}