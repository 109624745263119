import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import MembersContent from "./content/MembersContent";
import { useEffect } from "react";


export default function Members () {

    useEffect(() =>{
        document.title= `Revo | Members`
      }, []);     

    return(
        <div>
            <Header />
            <MembersContent/>
            <Footer />    
        </div>
    )
}