import React from "react";
import { useEffect } from "react";

export default function Address() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    
    useEffect(() =>{
        document.title= `Revo | API - Address`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Address</h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Get information data of specific RVO address. Balances, transactions, list, token holdings</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Address</h1>
                            <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                            <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>

                            <font color="white" style={{ fontSize: `26px !important` }}>Table of contents</font><br></br>
                            <ul style={{ color: `white` }}>
                                <li><b><a style={{ color: `white` }} href="https://revo.network/docs-api/address#address">Address</a></b>
                                    <ul>
                                        <li><a style={{ color: `white` }} href="#address-information">Address information</a></li>
                                        <li><a style={{ color: `white` }} href="#address-balance">Address Balance</a></li>
                                        <li><a style={{ color: `white` }} href="#address-transactions">Address Transactions</a></li>
                                        <li><a style={{ color: `white` }} href="#address-basic-transactions">Address Basic Transactions</a></li>
                                        <li><a style={{ color: `white` }} href="#address-contract-transactions">Address Contract Transactions</a></li>
                                        <li><a style={{ color: `white` }} href="#address-erc20-token-transactions">Address ERC20 Token Transactions</a></li>
                                        <li><a style={{ color: `white` }} href="#address-utxo-list">Address UTXO List</a></li>
                                        <li><a style={{ color: `white` }} href="#address-balance-history">Address Balance History</a></li>
                                        <li><a style={{ color: `white` }} href="#address-erc20-balance-history">Address ERC20 Balance History</a></li>
                                    </ul>
                                </li>
                            </ul>

                            <br></br>

                            <font id="address" color="white" style={{ fontSize: `26px !important` }}>Address</font>
                            <p className="pa-p-v1 pa-bright">Get information data of specific RVO address. Balances, transactions, list, token holdings</p>
                            <font id="address-information" color="white">Address information</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Display all known Revo address informations.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9" target="_blank">https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-balance" color="white">Address Balance</font>
                            <table style={{ width: `100%`, }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td style={{  }} ><font className="ap-first-h4">/address/:address/balance<br></br>/address/:address/balance/total-received<br></br>/address/:address/balance/total-sent<br></br>/address/:address/balance/unconfirmed<br></br>/address/:address/balance/staking<br></br>/address/:address/balance/mature</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Retrieve extra address data like balance,sent and received coins, unconfirmed UTXos, staking and matured coins</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance/total-received" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance/total-received</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance/total-sent" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance/total-sent</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance/unconfirmed" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance/unconfirmed</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance/staking" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance/staking</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance/mature" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance/mature</a><br></br>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-transactions" color="white">Address Transactions</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/txs</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns all transaction ids the address related to. Accepts pagination parameters and block/timestamp filter parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/txs?limit=10&offset=0&reversed=false" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balancetxs</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-basic-transactions" color="white">Address Basic Transactions</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/basic-txs</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns all transactions the address changes it's RVO balance. Accepts pagination parameters and block/timestamp filter parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/basic-txs?limit=5&offset=0&fromBlock=90000&toBlock=100000" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/basic-txs</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-contract-transactions" color="white">Address Contract Transactions</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/contract-txs<br></br>/address/:address/contract-txs/:contract</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns all transactions the address calls a contract. Accepts pagination parameters and block/timestamp filter parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/contract-txs/cbbca8a03ece54973581a1e99ec557803f2dacd9" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/contract-txs/cbbca8a03e...03f2dacd9</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-erc20-token-transactions" color="white">Address ERC20 Token Transactions</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/erc20-txs/:tokenAddress</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns all ERC20 transactions of a specific address and ERC20 Token contract. Accepts pagination parameters and block/timestamp filter parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/erc20-txs/756fc65c34db6f5076154bdda247b270ef5f608a/" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/erc20-txs/756fc64d...5f608a/</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-utxo-list" color="white">Address UTXO List</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/utxo</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns all UTXO for a specific address. Accepts pagination parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/utxo" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/utxo</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-balance-history" color="white">Address Balance History</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/balance-history</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns full address balance history. Accepts pagination parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/balance-history" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/balance-history</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="address-erc20-balance-history" color="white">Address ERC20 Balance History</font>
                            <table style={{ width: `100%` }}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/address/:address/erc20-balance-historyb<br></br>/address/:address/erc20-balance-history/:token</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{ marginBottom: `10px !important` }} className="pa-p-v1 pa-bright">Returns full total address ERC20 balance history. A single token address can be scanned Accepts pagination parameters. Can be reversed.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/erc20-balance-history" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/erc20-balance-history</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/address/RStakerWbSVo8k1PfLkCK5rTXEjmX3HQH9/erc20-balance-history/756fc65c34db6f5076154bdda247b270ef5f608a" target="_blank">https://api.revo.network/address/RStakerWb...rTXEjmX3HQH9/erc20-balance-history/756fc...f608a</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
