import React from "react";
import { useEffect } from "react";

export default function EVM() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const evm_state = `https://${window.location.hostname}/images/technology/evm-state.PNG`;
    const state_tx_function = `https://${window.location.hostname}/images/technology/state-tx-function.PNG`;
    const stack_memory = `https://${window.location.hostname}/images/technology/stack-memory.PNG`;
    const remix_ide = `https://${window.location.hostname}/images/technology/remix-ide.PNG`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - EVM`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>EVM</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Revo EVM was entirely ported from latest Ethereum source code, and included all features and components as it's predecessor.</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" style={{ } } className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Ethereum Virtual Machine</h1>
                            <p className="pa-p-v1 pa-bright">The EVM’s physical instantiation can’t be described in the same way that one might point to a cloud or an ocean wave, but it does exist as one single entity maintained by thousands of connected computers running a Revo client.</p>
                            <p className="pa-p-v1 pa-bright">The Revo protocol itself exists solely for the purpose of keeping the continuous, uninterrupted, and immutable operation of this special state machine; It's the environment in which all Revo accounts and smart contracts live. At any given block in the chain, Revo has one and only one 'canonical' state, and the EVM is what defines the rules for computing a new valid state from block to block.</p>
                            <p className="pa-p-v1 pa-bright">Some basic familiarity with common terminology in computer science such as bytes, memory, and a stack are necessary to understand the EVM. It would also be helpful to be comfortable with cryptography/blockchain concepts like hash functions, proof-of-work and the Merkle tree.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">From ledger to state machine</h1>
                            <p className="pa-p-v1 pa-bright">The analogy of a 'distributed ledger' is often used to describe blockchains like Bitcoin, which enable a decentralized currency using fundamental tools of cryptography. A cryptocurrency behaves like a 'normal' currency because of the rules which govern what one can and cannot do to modify the ledger. For example, a Bitcoin address cannot spend more Bitcoin than it has previously received. These rules underpin all transactions on Bitcoin and many other blockchains.</p>
                            <p className="pa-p-v1 pa-bright">While Revo has its own native cryptocurrency (RVO) that follows almost exactly the same intuitive rules, it also enables a much more powerful function using Ethereum EVM: Smart Contracts. For this more complex feature, a more sophisticated analogy is required. Instead of a distributed ledger, Revo is also a distributed state machine. Revo's state is a large data structure which holds not only all accounts and balances, but a machine state, which can change from block to block according to a pre-defined set of rules, and which can execute arbitrary machine code. The specific rules of changing state from block to block are defined by the EVM.</p>
                            <img style={{width: '60%', height: '30%'}} src={evm_state}/>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">The state transition function</h1>
                            <p className="pa-p-v1 pa-bright">The EVM behaves as a mathematical function would: Given an input, it produces a deterministic output. It therefore is quite helpful to more formally describe Revo as having a state transition function:</p>
                            <img style={{width: '10%', height: '30%'}} src={state_tx_function}/>
                            <p className="pa-p-v1 pa-bright">An old valid state (S) and a new set of valid transactions (T), the Ethereum state transition function Y(S, T) produces a new valid output state S' State</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">State</h1>
                            <p className="pa-p-v1 pa-bright">In the context of Revo, the state is an enormous data structure called a modified Merkle Patricia Trie, which keeps all accounts linked by hashes and is reducible to a single root hash stored on the blockchain itslef.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Transactions</h1>
                            <p className="pa-p-v1 pa-bright">Transactions are cryptographically signed instructions from accounts. There are two types of transactions: those which result in message calls and those which result in contract creation. Contract creation results in the creation of a new contract account containing compiled smart contract bytecode. Whenever another account makes a message call to that contract, it executes its bytecode.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">EVM instructions</h1>
                            <p className="pa-p-v1 pa-bright">The EVM executes as a stack machine with a depth of 1024 items. Each item is a 256-bit word, which was chosen for the ease of use with 256-bit cryptography (such as Keccak-256 hashes or secp256k1 signatures).</p>
                            <p className="pa-p-v1 pa-bright">During execution, the EVM maintains a transient memory (as a word-addressed byte array), which does not persist between transactions. Contracts, however, do contain a Merkle Patricia storage trie (as a word-addressable word array), associated with the account in question and part of the global state.</p>
                            <p className="pa-p-v1 pa-bright">Compiled smart contract bytecode executes as a number of EVM opcodes, which perform standard stack operations like XOR, AND, ADD, SUB, etc. The EVM also implements a number of blockchain-specific stack operations, such as ADDRESS, BALANCE, BLOCKHASH, etc.</p>
                            <img style={{width: '60%', height: '30%'}} src={stack_memory}/>
                            <h1 id="solitidy" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Solidity</h1>
                            <p className="pa-p-v1 pa-bright">Solidity is an object-oriented, high-level language for implementing smart contracts. Smart contracts are programs which govern the behaviour of accounts within the Ethereum state. Solidity is a curly-bracket language designed to target the Ethereum Virtual Machine (EVM). It is influenced by C++, Python and JavaScript.</p>
                            <p className="pa-p-v1 pa-bright">Solidity is statically typed, supports inheritance, libraries and complex user-defined types among other features. With Solidity you can create contracts for uses such as voting, crowdfunding, blind auctions, and multi-signature wallets.</p>
                            <p className="pa-p-v1 pa-bright">When deploying contracts, you should use the latest released version of Solidity. Apart from exceptional cases, only the latest version receives security fixes. Furthermore, breaking changes as well as new features are introduced regularly. We currently use a 0.y.z version number to indicate this fast pace of change.</p>
                            <h1 id="ide-and-public-compilers" className="pa-h1-v2 pa-bright fadeInDelay00Duration10"> IDE and Public Compiler</h1>
                            <p className="pa-p-v1 pa-bright">Solidity versions follow Semantic Versioning. In addition, patch level releases with major release 0 (i.e. 0.x.y) will not contain breaking changes. That means code that compiles with version 0.x.y can be expected to compile with 0.x.z where z &gt; y.</p>
                            <p className="pa-p-v1 pa-bright">In addition to releases, we provide nightly development builds with the intention of making it easy for developers to try out upcoming features and provide early feedback. Note, however, that while the nightly builds are usually very stable, they contain bleeding-edge code from the development branch and are not guaranteed to be always working. Despite our best efforts, they might contain undocumented and/or broken changes that will not become a part of an actual release. They are not meant for production use. When deploying contracts, you should use the latest released version of Solidity. This is because breaking changes, as well as new features and bug fixes are introduced regularly.</p>
                            <p className="pa-p-v1 pa-bright">On the Revo, you can dive straight into writing and compiling Solidiy code, directly from your browser, without the need to download libraries or install a compiler directly over the linux CLI.Available for free at any time and from any location it's a great tool to start developing , learning and directly deploying bytecode testing it's own execution</p>
                            <p className="pa-p-v1 pa-bright">The Revo Remix Compiler is available at</p>
                            <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://remix.revo.network</h4>
                            <img style={{width: '90%', height: '30%'}} src={remix_ide}/>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
