import React from "react";
import { useEffect } from "react";

export default function UTXO() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const utxo_bitcoin = `https://${window.location.hostname}/images/technology/utxo-bitcoin.PNG`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - UTXO and Account/Balance models`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>UTXO and Account/Balance models</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Revo accounting is based completly on official Bitcoin Core source code</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}}  className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Unspent Transaction Output Model</h1>
                            <p className="pa-p-v1 pa-bright">In Bitcoin, each transaction spends output from prior transactions and generates new outputs that can be spent by transactions in the future. All of the unspent transactions are kept in each fully-synchronized node, and therefore this model is named “UTXO”. A user’s wallet keeps track of a list of unspent transactions associated with all addresses owned by the user, and the balance of the wallet is calculated as the sum of those unspent transactions</p>
							<img style={{width: '70%', height: '30%'}} src={utxo_bitcoin}/>
							<br/>
							<p className="pa-p-v1 pa-bright">Let’s take a look at a simplified example of how the UTXO model works in Bitcoin transactions:</p>
							<ul>
								<li><p className="pa-p-v1 pa-bright">Alice gains 12.5 bitcoins through mining. Alice’s wallet is associated with one UTXO record of 12.5 bitcoins.</p></li>
								<li><p className="pa-p-v1 pa-bright">Alice wants to give Bob 1 bitcoin. Alice’s wallet first unlocks her UTXO of 12.5 bitcoins and uses this whole 12.5 bitcoins as input to the transaction. This transaction sends 1 bitcoin to Bob’s address and the reminder of 11.5 bitcoins is sent back to Alice in the form of a new UTXO to a newly-created address (owned by Alice).</p></li>
								<li><p className="pa-p-v1 pa-bright">Say there was another UTXO of 2 bitcoins associated with Bob prior to step 2, Bob’s wallet now shows that his balance is 3 bitcoins. Bob’s wallet now keeps track of two UTXOs: one from before and another from the transaction in step 2. Each UTXO needs to be unlocked if Bob wishes to spend them.</p></li>
							</ul>
							<h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Account/Balance Model</h1>
							<p className="pa-p-v1 pa-bright">The Account/Balance Model, on the other hand, keeps track of the balance of each account as a global state. The balance of an account is checked to make sure it is larger than or equal to the spending transaction amount. Here is a simplified example of how this model works in Ethereum:</p>
							<ul>
								<li><p className="pa-p-v1 pa-bright">Alice gains 5 ethers through mining. It is recorded in the system that Alice has 5 ethers.</p></li>
								<li><p className="pa-p-v1 pa-bright">Alice wants to give Bob 1 ether, so the system will first deduct 1 ether from Alice’s account, so Alice now has 4 ethers</p></li>
								<li><p className="pa-p-v1 pa-bright">The system then increases Bob’s account by 1 ether. The system knows that Bob has 2 ethers to begin with, therefore Bob’s balance is increased to 3 ethers.</p></li>
							</ul>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Benefits and drawbacks</h1>
                            <p className="pa-p-v1 pa-bright">Both models achieve the same goal of keeping track of account balances in a consensus system. The benefits of the UTXO Model are:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright"><strong>Scalability</strong> — Since it is possible to process multiple UTXOs at the same time, it enables parallel transactions and encourages scalability innovation.</p></li>
								<li><p className="pa-p-v1 pa-bright"><strong>Privacy</strong> — Even Bitcoin is not a completely anonymous system, but UTXO provides a higher level of privacy, as long as the users use new addresses for each transaction. If there is a need for enhanced privacy, more complex schemes, such as ring signatures, can be considered.</p></li>
							</ul>
                            <p className="pa-p-v1 pa-bright">On the other hand, the benefits of the Account/Balance Model are:</p>
                            <ul>
								<li><p className="pa-p-v1 pa-bright"><strong>Simplicity</strong> — Ethereum opted for a more intuitive model for the benefit of developers of complex smart contracts, especially those that require state information or involve multiple parties. An example is a smart contract that keeps track of states to perform different tasks based on them. UTXO’s stateless model would force transactions to include state information, and this unnecessarily complicates the design of the contracts.</p></li>
								<li><p className="pa-p-v1 pa-bright"><strong>Efficiency</strong> — In addition to simplicity, the Account/Balance Model is more efficient, as each transaction only needs to validate that the sending account has enough balance to pay for the transaction.</p></li>
							</ul>
                            <p className="pa-p-v1 pa-bright">One drawback for the Account/Balance Model is the exposure to double spending attacks. An incrementing nonce can be implemented to counteract this type of attack. In Ethereum, every account has a public viewable nonce and every time a transaction is made, the nonce is increased by one. This can prevent the same transaction being submitted more than once. (Note, this nonce is different from the Ethereum proof of work nonce, which is a random value.) Like most things in computer architecture, both models have trade-offs.</p>
                            <p className="pa-p-v1 pa-bright">In Revo, both technologies are present, which are exploited for the correct storage of balances and EVM compatibility</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
