import React from "react";
import { useEffect } from "react";

export default function PaginationParameters() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    
    useEffect(() =>{
        document.title= `Revo | API - Pagination Parameters`
      }, []);     

    return (
        <div style={{minHeight: `100vh`}}>
        <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>
    
            
    
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1 text-center">
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Pagination Parameters</h1>
                        <p className="pa-p-v1 pa-white fadeInDelay03Duration10">You may use one of 3 forms below, all indices count from 0, maximum 100 records per page:</p>
                        
                        </div>
                </div>
            </div>
    
        </section>
        
        <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div align="left" className="col-12 col-xl-10 offset-xl-1">
        
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Pagination Parameters</h1>	
                        <p className="pa-p-v1 pa-bright">You may use one of 3 forms below, all indices count from 0, maximum 100 records per page:</p>
                        <ul style={{color: `white`}}>
                            <li>limit=20&amp;offset=40</li>	
                            <li>from=40&amp;to=59</li>	
                            <li>pageSize=20&amp;page=2</li>		  
                        </ul>                        
                        <br></br>
                    </div>
                </div>
            </div>
        </section>
        </div>
    )
} 