import React from "react";
import { useEffect } from "react";

export default function Block() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    
    useEffect(() =>{
        document.title= `Revo | API - Block`
      }, []);     

    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Block</h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Display data of confirmed blockchain blocks</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Blocks</h1>
                            <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                            <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>

                            <font color="white"  style={{fontSize: `26px !important`}}>Table of contents</font><br></br>
                            <ul style={{color: `white`}}>
                                <li><b><a style={{color: `white`}} href="https://revo.network/docs-api/block#block">Block</a></b>
                                    <ul>
                                        <li><a style={{color: `white`}} href="#block-info">Block info</a></li>
                                        <li><a style={{color: `white`}} href="#blocks-of-date">Blocks of Date</a></li>
                                        <li><a style={{color: `white`}} href="#recent-blocks">Recent Blocks</a></li>
                                    </ul>
                                </li>
                            </ul>

                            <br></br>
                            <font id="block" color="white"  style={{fontSize: `26px !important`}}>Blocks</font><br></br>
                            <p className="pa-p-v1 pa-bright">Display data of confirmed blockchain blocks</p>
                            <font id="block-info" color="white">Block info</font>
                            <table style={{width: `100%`}}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/block/:height <br></br>/block/:hash</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td>
                                        <p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display block data informations based on a specific height or hash. A block contains multiple informations and data sets and returns transaction list confirmed inside the block itself.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/block/0" target="_blank">https://api.revo.network/block/0</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/block/0000134793d0d704e7d05a7a09c2b2a902b4affd6ff8e4ffb8ff061b3bbd4bfa" target="_blank">https://api.revo.network/block/0000134793d0d704e7d05a7a09c2b2a902b4affd6ff8e4ffb8ff061b3bbd4bfa</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="blocks-of-date" color="white">Blocks of Date</font>
                            <table style={{padding: `10px`, width: `100%`}}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/blocks<br></br>/blocks?date=2022-01-01</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td>
                                        <p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display all blocks of a specific date. Argument is date in ISO 8601 Date format. Default value: today.</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/blocks" target="_blank">https://api.revo.network/blocks</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/blocks?date=2022-10-10" target="_blank">https://api.revo.network/blocks?date=2022-10-10</a>
                                    </td>
                                </tr>
                            </table>
                            <br></br>
                            <font id="recent-blocks" color="white">Recent Blocks</font>
                            <table style={{width: `100%`}}>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                                    <td ><font className="ap-first-h4">/recent-blocks<br></br>/recent-blocks?count=5</font></td>
                                </tr>
                                <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                                    <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">Display recent blocks (default 10). Accepts count as argument</p>
                                        <font color="#1fcecb">Examples</font><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/recent-blocks" target="_blank">https://api.revo.network/recent-blocks</a><br></br>
                                        <a className="exampleapi" href="https://api.revo.network/recent-blocks?count=5" target="_blank">https://api.revo.network/recent-blocks?count=5</a>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
