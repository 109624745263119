import React, { useEffect, useState } from "react";
import axios from 'axios';
import Captcha from "../addons/Captcha";

export default function FaucetsContent() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;

    const [walletAddress, setWalletAddress] = useState("");

    const [transactionData, setTransactionData] = useState(false);

    const [lastClaimsState, setLastClaimsState] = useState(false);


    const [currentData, setCurrentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const [pagesNumber, setPagesNumber] = useState(0);

    const [updateStates, setUpdateStates] = useState(false);

    useEffect(async () => {
        let lastClaims = await axios.get("https://revo.network/api/getclaimed");
        setLastClaimsState((lastClaims.data).reverse());




        let pages = [];
        let pagesLength = Math.floor((lastClaims.data).length / 15);

        for (let i = 0; i <= pagesLength; i++) {
            pages.push(i + 1);
        }

        setPagesNumber(pages);
    }, [transactionData])

    const handleClaimButton = async () => {
        let validateAddressResp = await axios.post("https://revo.network/api/claimcoins", { walletAddress });

        if (validateAddressResp?.data?.txId || (validateAddressResp?.data?.txId)?.length == 0) {
            setTransactionData({
                txId: validateAddressResp.data.txId,
                coinsAmount: validateAddressResp.data.coinsAmount
            });
        } else if (validateAddressResp?.data) {
            setTransactionData({
                error: validateAddressResp.data
            });
        } else {
            alert("Error")
        }
    }






    const handlePagesButtons = (n) => {
        let firstPage = (n - 1) * 15;
        let lastPage = n * 15;
        let currentList = lastClaimsState.slice(firstPage, lastPage);
        setCurrentData(currentList);
        setCurrentPage(n);
    }

    ((!currentData.length && lastClaimsState.length) || (currentData.length && (lastClaimsState.slice(((currentPage-1) * 15), lastClaimsState.length)[currentPage]?.opDate !== currentData[currentPage]?.opDate))) && handlePagesButtons(1);


    transactionData && !updateStates && setUpdateStates(true);
    !transactionData && updateStates && setUpdateStates(false); 

    const tableVars = ["Transaction Id", "Date", "RVO Amount"];


    return (
        <div style={{ minHeight: `100vh` }}>
            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>



                <div className="container">
                    <div className="row" style={{}}>
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Faucets - Testnet</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Claim Testnet RVO</p>
                        </div>
                    </div>
                </div>

            </section>

            <section id="srvices" style={{ backgroundColor: `transparent` }} className="pa-what-we-do pa-standard-section text-center hc-four-section">

                <div className="container" style={{paddingLeft: "0", paddingRight: "0"}}>
                    <div className={transactionData ? "node-card-container faucet-cont tran-d" : "node-card-container faucet-cont tran-no" } >
                        {/*<div style={{display: `flex`}} className="node-card-divs">
							</div>*/}
                        <div className="" style={{ textAlign: `center`, width: "100%" }}>
                            <span style={{ color: `white`, fontSize: `25px` }}>Get your Testnet Revo Coins!</span>

                            {transactionData && (transactionData?.error ? <div style={{ marginTop: "50px" }}><h5 style={{ color: "red" }}>{transactionData.error}</h5></div> :
                                <div style={{ marginTop: "20px" }}>
                                    {(transactionData?.txId)?.length && <h5>Transaction Id</h5>}
                                    {(transactionData?.txId)?.length && <h5 style={{textOverflow: "ellipsis", overflow: "hidden"}}><a target="_blank"  href={`https://testnet.revo.network/tx/${transactionData.txId}`}>{transactionData.txId}</a></h5>}
                                    <div style={{ display: "flex", justifyContent: "center" }}>
                                        <h5>Coins Obtained: </h5> &nbsp;
                                        <h5 style={{ color: "green" }}>{transactionData.coinsAmount + "RVO"}</h5></div>
                                </div>)}

                            {!transactionData && <div style={{ textAlign: `justify` }}>
                                <div class="form-outline">
                                    <input onChange={(e) => { setWalletAddress(e.target.value) }} type="text" style={{ background: "#303238 !important", margin: "20px 0 10px 0", borderRadius: "0.375rem" }} placeholder="Enter your RVO Address" id="form12" class="" />
                                </div>
                            </div>}
                            {!transactionData && <Captcha handleClaimButton={handleClaimButton} setTransactionData={setTransactionData}/> }
                            {/*!transactionData && <button onClick={() => handleClaimButton()} className="btn btn-primary" style={{ marginTop: `15px` }}>CLAIM REVO COINS!</button>*/}
                            {transactionData && transactionData?.error && <button onClick={() => setTransactionData(false)} className="btn btn-danger" style={{ marginTop: `15px` }}>Back</button>}
                        </div>
                    </div>

                    <div style={{ display: "block", textAlign: "center", marginBottom: "20px"}}>

                        {
                            pagesNumber !== 0 && pagesNumber.length > 1 && pagesNumber.map(e => {
                                let backValue = e == currentPage ? "#272730" : "#121216";
                                return (
                                    <span style={{ margin: "5px", padding: "5px 10px", background: backValue, borderRadius: "5px", cursor: "pointer" }} onClick={() => handlePagesButtons(e)}>{e}</span>
                                )
                            })
                        }

                    </div>
                    {currentData.length > 0 && <table className="tb-primary" style={{  margin: "auto", maxWidth: "95vw"}}>
                        <tr className="tr-primary">
                            {
                                tableVars.map(e => {
                                    return (<td key={e}><strong>{e}</strong></td>)
                                })
                            }
                        </tr>
                        {
                            currentData.map((e, i) => {
                                return (<tr className="variable-tr">
                                    <td className="variable-td variable-tx" style={{maxWidth: "95vw"}}><div className="variable-name">{tableVars[0]}</div><div className="variable-value" style={{textOverflow: "ellipsis", overflow: "hidden"}}><a className="hc-first-a" target="_blank" href={`https://testnet.revo.network/tx/${e.transactionId}`}>{e.transactionId}</a></div></td>
                                    <td className="variable-td" style={{maxWidth: "95vw"}}><div className="variable-name">{tableVars[1]}</div><div className="variable-value">{(new Date(parseInt(e.opDate))).toLocaleString()}</div></td>
                                    <td className="variable-td" style={{maxWidth: "95vw"}} ><div className="variable-name">{tableVars[2]}</div><div className="variable-value">{e.coinsClaimed}</div></td>
                                </tr>)
                            })

                        }
                    </table>}
                </div>
            </section>
        </div>
    )
}