import React from 'react';
import '../../styles/ProductCards.css'


export default function ProductCards({ productName, productPrice, productImg, productSpec = "Product Spec", }) {


    return (
        <div class="products-card">
            <div className='product-img-container'>
                <img src={productImg} />
            </div>
            <div class="card-title-container">
            </div>
            <div class="price-container">
                <div class="price-div">
                    <span>{productPrice}</span>
                </div>
                <div class="styling-div">
                    <div></div>
                </div>
            </div>
            <div class="card-spec-container">
                <h2>{productName}</h2>
                <div class="styling-div-spec">
                    <div></div>
                </div>
                <div class="spec-content">
                    <ul>
                        <li><i class="fas fa-check" style={{ color: "#1f5120" }}></i> Spec 1</li>
                        <li><i class="fas fa-check" style={{ color: "#1f5120" }}></i> Spec 2</li>
                        <li><i class="fas fa-check" style={{ color: "#1f5120" }}></i> Spec 3</li>
                    </ul>
                </div>
            </div>
                <div class="buy-button">
                    <div>
                        <div class="button-back">BUY NOW</div>
                        <span href="#">BUY NOW</span>
                    </div>
                </div>
        </div>
    )
}
