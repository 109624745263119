
const initialState = {
    articles: [],
    homeLoaded: false
}

function rootReducer(state = initialState, action) {
    switch(action.type){
        case 'GET_ARTICLES': 
            return {
                ...state,
                articles: action.payload,
            }
        case 'HOME_LOADED':
            return {
                ...state,
                homeLoaded: action.payload
            }
        default: return state            
    }
}

export default rootReducer;