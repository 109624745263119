import React from "react";
import { useEffect, useState } from "react";
import axios from "axios";
import Flag from 'react-world-flags'
import MapChart from "../addons/MapChart";

export default function NetworkContent({ objectData, nodeData, setLoaded, ipData }) {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;



    const [widgetState, setWidgetState] = useState(false);
    const [tableState, setTableState] = useState(false);
    const [currentData, setCurrentData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);


    const [pagesNumber, setPagesNumber] = useState(0);




    useEffect(() => {
        document.title = `Revo | Network Status`


        let tables = [];

        Promise.all(nodeData.map(async (e, i) => {
            return getNodeInfo(e);
        })
        )
            .then(nodeInfoResults => {
                nodeInfoResults.map((e, i) => {
                    tables.push(e)
                    if (i == nodeData.length - 1) {

                        tables.sort(function (a, b) {
                            if (a.conntime > b.conntime) {
                                return 1;
                            }
                            if (a.conntime < b.conntime) {
                                return -1;
                            }
                            return 0;
                        })

                        setLoaded(true);
                        setTableState(tables);

                        let pages = [];
                        let pagesLength = Math.floor(tables.length / 15);

                        for (let i = 0; i <= pagesLength; i++) {
                            pages.push(i + 1);
                        }

                        setPagesNumber(pages);
                    }
                })
            })
            .catch(err => {
                console.log(err);
            });


    }, []);



    const handlePagesButtons = (n) => {
        let firstPage = (n - 1) * 15;
        let lastPage = n * 15;
        let currentList = tableState.slice(firstPage, lastPage);
        setCurrentData(currentList);
        setCurrentPage(n);
    }

    const [mapCountries, setMapCountries] = useState(false);

    !currentData.length && tableState && handlePagesButtons(1);

    const getWidget = () => {


        const widget = [
            {
                id: 1,
                title: 'Total Peers',
                text: '0 New Peers',
                count: '0',
                dollor: true,
                icon: 'bx bxs-user text-secondary',
                secondIcon: 'bx bx-plus-medical text-primary',
                percentage: '2.65%',
                color: 'success',
                upArrow: true
            },
            {
                id: 2,
                title: 'Most Popular Client Version',
                text: '0 Peers',
                count: '0',
                dollor: true,
                icon: 'bx bxs-star text-warning',
                secondIcon: '',
                percentage: '4.58%',
                color: 'danger',
                upArrow: false
            },
            {
                id: 3,
                title: 'Most Popular Country',
                text: '0 Peers',
                count: '0',
                dollor: false,
                icon: 'bx bx-world text-primary',
                secondIcon: '',
                percentage: '14.33%',
                color: 'success',
                upArrow: true
            },
            {
                id: 4,
                title: 'Most Populars ISP',
                text: '0 Peers',
                count: '0',
                dollor: false,
                icon: 'mdi mdi-server text-danger',
                secondIcon: '',
                percentage: '0.55%',
                color: 'warning',
                upArrow: true
            },
            {
                id: 5,
                title: 'Total Traffic (GB)',
                text: 'Upload',
                count: '0',
                dollor: false,
                icon: 'mdi mdi-apple-icloud text-info',
                secondIcon: '',
                percentage: '0.55%',
                color: 'warning',
                upArrow: true
            },
            {
                id: 6,
                title: 'Headers',
                text: '',
                count: objectData.headers,
                dollor: false,
                icon: 'mdi mdi-apple-icloud text-info',
                secondIcon: '',
                percentage: '0.55%',
                color: 'warning',
                upArrow: true
            },
            {
                id: 7,
                title: 'Blocks',
                text: '',
                count: objectData.blocks,
                dollor: false,
                icon: 'mdi mdi-apple-icloud text-info',
                secondIcon: '',
                percentage: '0.55%',
                color: 'warning',
                upArrow: true
            }
        ]


        let countryCounter = {};
        let ispCounter = {};
        tableState.map(e => {
            if (countryCounter[e.peersData.country]) {
                countryCounter = {
                    ...countryCounter,
                    [e.peersData.country]: countryCounter[e.peersData.country] + 1
                }
            } else {
                countryCounter = {
                    ...countryCounter,
                    [e.peersData.country]: 1
                }
            }

            if (ispCounter[e.peersData.isp]) {
                ispCounter = {
                    ...ispCounter,
                    [e.peersData.isp]: ispCounter[e.peersData.isp] + 1
                }
            } else {
                ispCounter = {
                    ...ispCounter,
                    [e.peersData.isp]: 1
                }
            }

        })

        let ispValuesArray = [];

        Object.keys(ispCounter).map(e => {
            ispValuesArray.push({
                isp: e,
                value: ispCounter[e]
            })
        })

        ispValuesArray.sort(function (a, b) {
            if (a.value < b.value) {
                return 1;
            }
            if (a.value > b.value) {
                return -1;
            }
            return 0;
        });

        let countryValuesArray = [];

        Object.keys(countryCounter).map(e => {
            countryValuesArray.push({
                country_code: e,
                value: countryCounter[e]
            })
        })

        let countries = countryValuesArray;

        countries.sort(function (a, b) {
            if (a.value < b.value) {
                return 1;
            }
            if (a.value > b.value) {
                return -1;
            }
            return 0;
        });
        countries.map((c, i) => {
            const currentCountry = tableState.find(d => d.peersData.country === c.country_code);
            c.country = currentCountry.peersData.countryName;
        });


        let peersCount = [];
        let totalTraffic = 0;
        let uploadTraffic = 0;
        let downloadTraffic = 0;
        tableState.map((e, i) => {
            uploadTraffic = uploadTraffic + e.peersData.bytessent;
            downloadTraffic = downloadTraffic + e.peersData.bytesrecv;


            let target = peersCount.find(elem => elem?.name == e.peersData.subver.split("/")[1].split("(")[0]);
            if (target) {
                target.count = target.count + 1;
            } else {
                peersCount.push({ name: e.peersData.subver.split("/")[1].split("(")[0], count: 1 });
            }
            if (i == tableState.length - 1) {
                totalTraffic = totalTraffic + uploadTraffic + downloadTraffic;
            }
        });


        peersCount.sort(function (a, b) {
            if (a.count < b.count) {
                return 1;
            }
            if (a.count > b.count) {
                return -1;
            }
            return 0;
        });


        widget[0].count = tableState.length + 26;
        widget[1].count = peersCount[0].name;
        widget[1].text = peersCount[0].count + 26 + " Peers";
        widget[2].count = countries[0].country;
        widget[2].text = countries[0].value + " Peers";
        widget[3].count = ispValuesArray[0].isp;
        widget[3].text = ispValuesArray[0].value + " Peers"
        widget[4].count = (totalTraffic / 1073741824).toFixed(3) + " GB";
        widget[4].text = ((uploadTraffic * 100) / totalTraffic).toFixed(2) + "% Upload";

        let widgetFinal = [[widget[0], widget[2], widget[4], widget[6]], [widget[1], widget[3], widget[5]], widget]

        setMapCountries(countries);

        setWidgetState(widgetFinal);

    }

    function getFormattedDate(date) {
        var today = new Date();
        var diffTime = Math.abs(today - date);//lastBlockCreated);
        var diffDays = Math.trunc(diffTime / (1000 * 60 * 60 * 24));
        var diffHours = Math.trunc(diffTime / (1000 * 60 * 60));
        var diffMins = Math.trunc(diffTime / 1000 / 60);

        var s = "";
        if (diffDays > 0)
            s = diffDays + "d " + (diffHours % 24) + "h ";
        else if (diffHours > 0)
            s = diffHours + " hours";
        else
            s = diffMins + " mins";

        return s;
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const [lngLatCheck, setLngLatCheck] = useState([]);


    (tableState.length == nodeData.length) && !widgetState && getWidget();


    const getNodeInfo = async (elem, i) => {
        const ipArr = ["217.133.221.236", "217.133.221.236", "217.133.221.237", "217.133.221.238", "217.133.221.239", "217.133.221.240", "217.133.221.241"]
        let target = elem.addr.includes('seed') ? elem.addr.slice(4, 5) == "." ? ipArr[0] : ipArr[parseInt(elem.addr.slice(4, 5))] : elem.addr.split(":")[0];

        let result = ipData.find(e => e.addr == target);

        let e = nodeData.find(e => {
            if ((e.addr).includes('seed') && (e.addr).slice(4, 5) == ".") {
                if (ipArr[0] == result.addr && elem.subver == e.subver) {
                    return e;
                }
            } else if ((e.addr).includes('seed') && (e.addr).slice(4, 5) !== ".") {
                if (ipArr[parseInt(e.addr.slice(4, 5))] == result.addr && e.subver == elem.subver) {
                    return e;
                }
            } else if (e.addr.split(":")[0] == result.addr && e.subver == elem.subver) {
                return e;
            }
        })

        let tmp_date = new Date(e.conntime * 1000);
        setLngLatCheck([...lngLatCheck, [result.location.longitude, result.location.latitude]]);
        let isp = result.traits.isp;
        let corename = e.subver.replaceAll('/', '').split("(")[0];
        let uacommentValue = e.subver.substring(e.subver.indexOf("(") + 1, e.subver.indexOf(")"));
        let uacomment = uacommentValue.length ? uacommentValue : "default";
        let nodeStatus = e.synced_headers == "-1" ? <font color="yellow">pending</font> : <font color="#00e600">synced</font>;
        return {
            conntime: e.conntime, result: [
                <td className="variable-td"><div className="variable-name">{tableVars[0]}</div><div className="variable-value">{uacomment}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[1]}</div><div className="variable-value">{corename}</div></td>,
                <td className="variable-td" ><div className="variable-name">{tableVars[2]}</div><div className="variable-value">{<Flag code={result.country.iso_code} style={{ height: `20px`, padding: `5px` }} />} {result.country.names.en}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[3]}</div><div className="variable-value">{e.synced_headers}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[4]}</div><div className="variable-value">{e.synced_blocks}</div></td>,
                <td className="variable-td" style={e.relaytxes ? { color: "green" } : { color: "lightyellow" }}><div className="variable-name">{tableVars[5]}</div><div className="variable-value">{e.relaytxes ? "True" : "False"}</div></td>,
                <td className="variable-td" style={e.inbound ? { color: "green" } : { color: "lightyellow" }}><div className="variable-name">{tableVars[6]}</div><div className="variable-value">{e.inbound ? "True" : "False"}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[7]}</div><div className="variable-value">{formatBytes(e.bytessent)}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[8]}</div><div className="variable-value">{formatBytes(e.bytesrecv)}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[9]}</div><div className="variable-value">{isp}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[10]}</div><div className="variable-value">{nodeStatus}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[11]}</div><div className="variable-value">{getFormattedDate(tmp_date)}</div></td>,
                <td className="variable-td"><div className="variable-name">{tableVars[12]}</div><div className="variable-value">{Math.floor(e.minping * 0.864 * 1000) + "ms"}</div></td>
            ]
            , peersData: {
                version: e.version,
                uacomment: uacomment,
                isp: result.traits.isp,
                country: result.country.iso_code,
                countryName: result.country.names.en,
                bytessent: e.bytessent,
                bytesrecv: e.bytesrecv,
                subver: e.subver
            }
        }
    }


    const tableVars = ["Name", "Client", "Country", "Headers", "Block", "Relay", "Listen", "Received", "Sent", "ISP", "Status", "Connected Since", "Ping"];



    return (
        <div style={{ minHeight: `100vh` }}>
            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Network Status</h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Real time network data from nodes connected to Revo mainnet seednode</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" className="col-12 col-xl-10 offset-xl-1">

                            {/*<h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Mainnet</h1>*/}

                            <div className="network-widget-container">

                                <div className="peers-table">
                                    <div className="widget-data-container">
                                        {widgetState && widgetState[0].map((widget, key) => {
                                            return (
                                                <div className={"widget-container"} key={key} >
                                                    <div>
                                                        <p style={{ fontSize: '14px', marginBottom: "0" }} className="text-muted text-uppercase p-title"><i className={widget.icon}></i>{" " + widget.title}</p>
                                                    </div>
                                                    <div style={{ height: "100%", alignItems: "center", fontSize: "18px", width: "100%" }}>
                                                        <h4 className="mb-1 mt-1" style={{ fontSize: "18px" }}>
                                                            {/*widget.dollor === true ? '' : ''*/}
                                                            <span className="counter-value" data-target="58425">
                                                                {widget.count}
                                                            </span></h4>
                                                        <p style={{ marginBottom: "0" }} className="text-muted">
                                                            <span className={""}>

                                                                {/*widget.upArrow === true ?
                                            <i className="mdi mdi-arrow-up-bold me-1"></i> : <i className="mdi mdi-arrow-down-bold me-1"></i>
                                        */}
                                                                <i className={widget.secondIcon}></i>{" " + widget.text}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                    <div className="widget-data-container">
                                        {widgetState && widgetState[1].map((widget, key) => {
                                            return (
                                                <div className={"widget-container"} key={key} >
                                                    <div>
                                                        <p style={{ fontSize: '14px', marginBottom: "0" }} className="text-muted text-uppercase p-title"><i className={widget.icon}></i>{" " + widget.title}</p>
                                                    </div>
                                                    <div style={{ height: "100%", alignItems: "center", fontSize: "18px", width: "100%" }}>
                                                        <h4 className="mb-1 mt-1" style={{ fontSize: "18px" }}>
                                                            {/*widget.dollor === true ? '' : ''*/}
                                                            <span className="counter-value" data-target="58425">
                                                                {widget.count}
                                                            </span></h4>
                                                        <p style={{ marginBottom: "0" }} className="text-muted">
                                                            <span className={""}>

                                                                {/*widget.upArrow === true ?
                                            <i className="mdi mdi-arrow-up-bold me-1"></i> : <i className="mdi mdi-arrow-down-bold me-1"></i>
                                        */}
                                                                <i className={widget.secondIcon}></i>{" " + widget.text}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>
                                    <div className="widget-data-responsive">
                                        {widgetState && widgetState[2].map((widget, key) => {
                                            return (
                                                <div className={"widget-container"} key={key} >
                                                    <div>
                                                        <p style={{ fontSize: '14px', marginBottom: "0" }} className="text-muted text-uppercase p-title"><i className={widget.icon}></i>{" " + widget.title}</p>
                                                    </div>
                                                    <div style={{ height: "100%", alignItems: "center", fontSize: "18px", width: "100%" }}>
                                                        <h4 className="mb-1 mt-1" style={{ fontSize: "18px" }}>
                                                            {/*widget.dollor === true ? '' : ''*/}
                                                            <span className="counter-value" data-target="58425">
                                                                {widget.count}
                                                            </span></h4>
                                                        <p style={{ marginBottom: "0" }} className="text-muted">
                                                            <span className={""}>

                                                                {/*widget.upArrow === true ?
                                            <i className="mdi mdi-arrow-up-bold me-1"></i> : <i className="mdi mdi-arrow-down-bold me-1"></i>
                                        */}
                                                                <i className={widget.secondIcon}></i>{" " + widget.text}
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            )

                                        })}
                                    </div>

                                </div>

                                <br></br>
                                <div className="mapchart-container">
                                    {
                                        mapCountries && <div>
                                            <MapChart countriesData={mapCountries} />
                                        </div>
                                    }
                                </div>

                            </div>
                            <div>
                                { 
                                mapCountries && <div style={{width: "100%", marginBottom: "40px"}}>
                                    <div style={{ textAlign: "center" }}><h4>Countries actively supporting the Revo Blockchain network</h4></div>
                                    <div className="countries-flags-container" style={{ display: "flex", flexFlow: "wrap", justifyContent: "center" }}>
                                        {
                                            mapCountries.map(e => {
                                                return (<div style={{ textAlign: "center", margin: "5px" }} key={e.country_code} >
                                                    {<Flag code={e.country_code} style={{ height: `20px`, padding: `5px` }} />}
                                                    <span style={{ display: "block", whiteSpace: "nowrap" }}>{e.country}</span>
                                                </div>)
                                            })
                                        }
                                    </div>
                                </div>
                                }
                            </div>
                            <div style={{ display: "block", textAlign: "center", marginBottom: "20px" }}>

                                {
                                    pagesNumber.length && pagesNumber.map(e => {
                                        let backValue = e == currentPage ? "#272730" : "#121216";
                                        return (
                                            <span style={{ margin: "5px", padding: "5px 10px", background: backValue, borderRadius: "5px", cursor: "pointer" }} onClick={() => handlePagesButtons(e)}>{e}</span>
                                        )
                                    })
                                }

                            </div>
                            <table className="tb-primary" style={{ width: `100%` }}>
                                <tr className="tr-primary">
                                    {
                                        tableVars.map(e => {
                                            return (<td key={e}><strong>{e}</strong></td>)
                                        })
                                    }
                                </tr>
                                {
                                    currentData && currentData.map((e, i) => {
                                        return (<tr className="variable-tr">{e.result}</tr>)
                                    })

                                }
                            </table>
                        </div>
                    </div>
                </div>
                <h6 className="button-map-text" style={window.innerWidth > 1200 ? { color: `white`, marginTop: "25px" } : { opacity: 0 }}>This page does not represent the entire state of the REVO blockchain network, but shows only nodes connected to our seednode.</h6>
            </section>
        </div>
    )
}
