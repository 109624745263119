import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";

export default function ProfilesContent() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;


    const pablolizarraga = `https://${window.location.hostname}/images/members/pablolizarraga.png`;

    let userData = {
        name: "Pablo Lizarraga",
        stack: "Full Stack Web Developer",
        about: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum vel vulputate nibh. Quisque nec eleifend nisl. Aenean sed urna ut nibh tristique lacinia vitae ac ipsum. Donec laoreet interdum tortor, sit amet volutpat eros cursus sed. Nullam accumsan tristique ante, eu tincidunt mauris. Cras eleifend tempus dui mattis commodo. Nunc tincidunt lacus nibh, sed commodo enim cursus id. Ut eget felis eu libero consectetur euismod. Ut accumsan gravida dolor, a ornare massa viverra vel. Proin venenatis malesuada est. Nunc consectetur orci id iaculis interdum. Vivamus eu ex nec ante aliquet tincidunt. Proin maximus ex in lacus scelerisque tempor. Nullam quis magna diam. Ut in odio vel nunc porta ultricies. Mauris consequat iaculis magna, ut dictum ex tincidunt vel.",

    }


    const nicola = `https://${window.location.hostname}/images/members/nicola.png`;
    const miodrag = `https://${window.location.hostname}/images/members/miodrag.png`;
    const goran = `https://${window.location.hostname}/images/members/goran.png`;
    const marcozorzan = `https://${window.location.hostname}/images/members/marcozorzan.png`;
    const cesarecarli = `https://${window.location.hostname}/images/members/cesarecarli.png`;
    const chrislu = `https://${window.location.hostname}/images/members/chrislu.png`;
    const gianpieroleoni = `https://${window.location.hostname}/images/members/gianpieroleoni.png`;
    const noprofile = `https://${window.location.hostname}/images/members/noprofile.png`;

    let members = [
        {
            name: "Nicola Bertelli",
            description: "Nicola has been involved in IT and blockchain development since 2014. During his career he worked as technician on behalf of Unisys, Sisal and Dell, especially in the sys and network areas..",
            profession: "CEO & Founder - Core Developer",
            img: nicola,
            linkedin: "https://www.linkedin.com/in/nicola-bertelli-a6b145188/"
        },

        {
            name: "Miodrag Popovic",
            description: "Miodrag is a long-time developer, in recent years thanks to his knowledge in software programming he has approached the world of blockchain development thanks to ZeroClassic..",
            profession: "Core Developer",
            img: miodrag,
            linkedin: "#"
        },

        {
            name: "Goran Apostoloski",
            description: "Goran Apostoloski is a passionate graphic designer and web developer, who uses all Adobe software solutions to create beautiful designs. Involved in crypto since 2013..",
            profession: "Design & Web",
            img: goran,
            linkedin: "https://www.linkedin.com/in/cryptodesign/"
        },

        {
            name: "Marco Giovanni Zorzan",
            description: "Marco has many years of experience in the IT field, gained in many sectors. Developer and technician in the automation, iOT and management software sector. An expert in C / C ++, Visual Basic, Java..",
            profession: "Software Developer",
            img: marcozorzan,
            linkedin: "https://www.linkedin.com/in/marco-z-a8a817215/"
        },

        {
            name: "Cesare Carli",
            description: "Cesare Carli is an IT consultant and professional trainer. Technology and Free Software evangelist since the late 1990s, he is strongly committed to spread knowledge about possibilities and applications in the blockchain field...",
            profession: "Training",
            img: cesarecarli,
            linkedin: "https://www.linkedin.com/in/cesare-carli-24807ab/"
        },

        {
            name: "Pablo Lizárraga",
            description: "Pablo is passionate about the world of information technology. In 2015 he began his engineering career in information systems. In the third year of his degree, he decided to focus entirely on programming..",
            profession: "Frontend Developer",
            img: pablolizarraga,
            linkedin: "https://www.linkedin.com/in/pablo-matias-lizarraga/"
        },

        {
            name: "Chris Lu",
            description: "Chris Lu is a software engineer, developer of SeaWeedFS, a completely open source software that allows the creation of distributed data storage networks in a very efficient and distributed way..",
            profession: "Developer - Advisor",
            img: chrislu,
            linkedin: "https://www.linkedin.com/in/chrislu/"
        },

        {
            name: "Gian Piero Leoni",
            description: "Gian Piero entered into the crypto world in 2012, back in the days when mining was predominantly cpu/gpu and early home ASICs. During these years he met a lot of people from the BitcoinTalk forum..",
            profession: "Community Manager",
            img: gianpieroleoni,
            linkedin: "#"
        }
    ]



    useEffect(() => {
        document.title = `Revo | Profiles`

    }, []);


    return (
        <div style={{ minHeight: `100vh` }}>
            <section className="" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`, display: "flex", position: "relative", alignItems: "center", width: "100%", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "50%", padding: "105px 0 20px 0", flexDirection: "column" }}>
                <div style={{ width: "40%", display: "flex", justifyContent: "center", marginTop: "70px" }}>
                    <div style={{ width: "180px", borderRadius: "100%", padding: "6px", backdropFilter: "blur(10px)" }}>
                        <img style={{ width: "100%", height: "100%", borderRadius: "100%", padding: "0" }} src={pablolizarraga} />
                    </div>
                </div>

                <div className="container" style={{ marginTop: "15px" }}>
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-bright fadeInDelay00Duration10"  style={{ marginBottom: "0 !important", fontSize: "40px", fontWeight: "200", lineHeight: "53px" }} >{userData.name}</h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">{userData.stack}</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" className="col-12 col-xl-10 offset-xl-1">


                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">About</h1>
                            <p className="pa-p-v1 pa-bright" >{userData.about}</p>



                            <h2 className="more-news">Meet more members</h2>
                            {
                                <div className="articles-container articles-cont" style={{justifyContent: "space-evenly"}}>
                                    {members.length && members.filter(e => e.name !== userData.name).slice(0, 3).map(e => {
                                        return (
                                            <div key={e.name} className="article memb-card pa-hero pa-centered-section pa-full-height pa-image-back pushable-content " style={{height: "475px", width: "275px"}}>
                                            <Link className="article-link" style={{marginTop: "210px"}} to={`/news/${e.name.toLowerCase().replaceAll(" - "," ").replaceAll(" ", "-")}`} onClick={() => {}}>Read About</Link>
                                                <img style={{ width: `100%`, height: `275px`, padding: `0` }} src={e.img ? e.img : noprofile} />
                                                <div style={{padding: "15px"}} className="">
                                                    <h4 style={{ color: `#ffffff` }}>{e.name}</h4>
                                                    <div className=""><span>{e.profession}</span></div>
                                                    <h5 style={{color: "#9ec7d9 !important"}}>{e.description}</h5>
                                                </div>
                                                {/* Linkedin 
                                                <a class="btn btn-primary linkedin" style={{ backgroundColor: `transparent`, position: `absolute`, bottom: `0`, border: `none` }} target="_blank" href={e.linkedin} role="button"
                                                ><i class="fab fa-linkedin-in" ></i></a>*/}
                                                
                                                <div className="article-back"></div>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                            }

                            <div className="network-widget-container">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
