import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import PartnersContent from "./content/PartnersContent";
import { useEffect } from "react";


export default function Partners () {

    useEffect(() =>{
        document.title= `Revo | Partners`
      }, []);     

    return(
        <div>
            <Header />
            <PartnersContent/>
            <Footer />    
        </div>
    )
}