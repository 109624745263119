import React from "react";
import "../styles/assets/vendor/bootstrap/bootstrap.min.css"
import "../styles/assets/vendor/animate/animate.min.css"
import "../styles/assets/vendor/offcanvas-nav/hc-offcanvas-nav.css"
import "../styles/assets/css/particle-theme.css"
import "../styles/assets/css/particle-colors.css"
import "../styles/assets/vendor/simple-forms/css/simple-forms-skilltech-mod.css"
import Regular from "./navbar/regular";
import Mobile from "./navbar/mobile";

export default function Header () {
    return (
        <div>
        {/* Page Transitions
        ================================================== 
        <div className="pa-page-transition pa-page-transition-1 is-active"></div>
        <div className="pa-overlay" ></div>*/}
            <Regular /> 
            <Mobile />            
        </div>
    )
}