import React from "react";
import { useEffect } from "react";

export default function Sidechains() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const sidechain_schema = `https://${window.location.hostname}/images/technology/sidechain-schema.PNG`;
    const tx_tps = `https://${window.location.hostname}/images/technology/tx-tps.PNG`;
    
    useEffect(() =>{
        document.title= `Revo | Technology - SideChains (Enterprise)`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>SideChains (Enterprise)</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Need for speed</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">SideChains (Enterprise)</h1>
                            <p className="pa-p-v1 pa-bright">As widely described in one of the previous chapters, one of the most important challenges of blockchains that profess to be public while maintaining a closed and centralized consensus protocol (e.g. Proof of Authority) is to guarantee the immutability of data within the blockchain.</p>
                            <p className="pa-p-v1 pa-bright">Not only that, what is perhaps even more important to understand is that the blockchain, by its nature, will never be able to guarantee the authenticity of the data: this should make us understand that in certain situations it is necessary for an independent third party to be able to certify the correct insertion.</p>
                            <p className="pa-p-v1 pa-bright">Take for example a large company that produces canned tuna, which decides to address its consumers in a transparent way, providing a tool that allows us to trace the origin of the tuna. This company works with very important volumes of lots, raw materials and supply chain processes and consequently needs data entry to be done very quickly and precisely. Usually for these types of processes different technologies come into play, which must interact with each other without delay. Just think of a conveyor belt that moves our tuna cans from the packaging department to the labeling department.</p>
                            <p className="pa-p-v1 pa-bright">The smart contract that generates the identification codes for each label cannot wait for some miner to validate its transaction, the production chain would stop or suffer significant delays. For this reason it is necessary to develop an internal blockchain, where the validation of transactions takes place instantly.</p>
                            <p className="pa-p-v1 pa-bright">Revo implements an innovative technology, based exclusively on smart contracts, which allows Sidechains to self-notarize on the primary blockchain (Revo itself). Compared to other systems, which only notarize the hash of a snapshot of the private blockchain generated at regular intervals, Revo maintains a public and real-time history of the blocks generated by the Sidechains. Having real confirmation between the hash of the previous block and the next one of the Sidechain, let's create a timeline capable of making it impossible to tamper with: restoring the guarantee of data immutability.</p>
                            <h1 id="model" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Sidechain Model</h1>
                            <p className="pa-p-v1 pa-bright">The scheme and the operating model of a Sidechain on Revo is in itself very simple: Revo acts as a primary public blockchain (being in fact public, decentralized and free) on which a dedicated Smart Contract is loaded has the task of functioning as a connector and save area for the SideChain timeline. Each data block is notarized on chain in epochs to avoid spamming too many transactions on the primary blockchain.</p>
                            <img style={{width: '80%', height: '30%'}} src={sidechain_schema}/>
                            <h1 id="network-consensus" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Network and Conensus Protocol</h1>
                            <p className="pa-p-v1 pa-bright">The above protocol is in fact a layer 2. It means that it is completely independent from what the primary blockchain is: it only exploits its primary characteristics. Revo exists also in its enterprise version dedicated to big Companies: is a blockchain working on PoA protocol (Proof Of Authority), designed and optimized down to the smallest detail to guarantee over 10,000,000 transactions per second for intesive use case scenarios.</p>
                            <p className="pa-p-v1 pa-bright">This does not mean that the SideChains will be a closed environment, far from it. The entire source code of the Smart Contract will be made open source to allow any existing blockchain to self-notarize on Revo, using a native environment with minimal transaction and data saving costs.</p>
                            <h1 id="scalability-tps" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Speed, scalability and TPS</h1>
                            <p className="pa-p-v1 pa-bright">The battle for a scalable solution is the blockchain’s moon race. Bitcoin processes 4.6 transactions per second. Visa does around 1,700 transactions per second on average (based on a calculation derived from the official claim of over 150 million transactions per day). The potential for adoption is there but is bottlenecked currently by scalability.</p>
                            <p className="pa-p-v1 pa-bright">A study published by Tata Communications in 2018 showed that 44% of organizations in its survey are adopting blockchain, but also alludes to the universal problems that arise from deploying new technologies. From an architectural level, the unsolved problem of scalability is emerging as a bottleneck to blockchain adoption and practical applications.</p>
                            <p className="pa-p-v1 pa-bright">As Deloitte Insights puts it, “blockchain-based systems are comparatively slow. Blockchain’s sluggish transaction speed is a major concern for enterprises that depend on highperformance legacy transaction processing systems.” The world received a taste of the scalability problems in 2017 and 2018: severe transfer delays and high fees on the Bitcoin network, and the notorious Cryptokitties app that congested the Ethereum blockchain network (a network that thousands of decentralized applications rely on).</p>
                            <p className="pa-p-v1 pa-bright">In order to scale a blockchain, increasing the block size or decreasing the block time by reducing the hash complexity is not enough. With either method, the ability to scale reaches a ceiling before it can hit the transactions necessary to compete with businesses like Visa, which “handles an average of 150 million transactions every day” or around 1,736 transactions per second (TPS).</p>
                            <p className="pa-p-v1 pa-bright">By comparison, Bitcoin transaction speeds are significantly lower. Currently, the block size is set to 1MB (1,048,576 bytes — although through SegWit, that size can scale to up to a theoretical 4MB) and the average transaction size is 380.04 bytes (assuming that each transaction is from one wallet to x other wallets — so a batch transaction would count as one transaction. I’ll talk more about batch transactions later and why I labeled it this way) and seems to be on the rise.Therefore, the average amount of transactions that can fit into one of Bitcoin’s blocks, currently, is calculated as:</p>
                            <img style={{width: '50%', height: '30%'}} src={tx_tps}/>
                            <p className="pa-p-v1 pa-bright">The current Bitcoin block generation time is 10 minutes; i.e., every ten minutes, a new block is mined. In ten minutes (600 seconds), Bitcoin can average around 2,759.12 transactions based on previous assumptions. In other words, the Bitcoin blockchain can currently guarantee only 4.6 transactions per second</p>
                            <p className="pa-p-v1 pa-bright">Revo implements a fully dynamic and scalable block size, thanks to the decentralized onchain governance protocol. Thanks to a very precise consensus mechanism, the blockchain itself is able to correctly size the block size from a few bytes up to a few tens of megabytes.</p>
                            <p className="pa-p-v1 pa-bright">By default, the maximum size per block is 2,000,000 bytes, with an average transaction weight of around 320 bytes, along with block spacing of just 30 seconds, Revo can include up to 6250 transactions in a single block, which, compared to Bitcoin leads to a share increase of 1360%, with a theoretical TPS of over 210 validations per second.</p>
                            <p className="pa-p-v1 pa-bright">Should the need arise to scale in terms of TPS, the community can freely and independently propose and vote on raising the maximum limit for block sizes through the OGP protocol. Given the importance of keeping revo as decentralized as possible, the only physical limitation in terms of scalability is the only network latency between network nodes.</p>
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">PoA Sidechains</h1>
                            <p className="pa-p-v1 pa-bright">Proof of Authority (PoA) is a reputation-based consensus algorithm that introduces a practical and efficient solution for blockchain networks (especially private ones). The term was proposed in 2017 by Ethereum co-founder and former CTO Gavin Wood.</p>
                            <p className="pa-p-v1 pa-bright">The PoA consensus algorithm makes use of the value of identities. This means block validators stake their reputation instead of coins. As a result, PoA blockchains are protected by validation nodes which are arbitrarily selected as trusted entities.</p>
                            <p className="pa-p-v1 pa-bright">The Proof of Authority model relies on a limited number of validators, making it a highly scalable system. Blocks and transactions are verified by pre-approved participants, who act as system moderators.The PoA consensus algorithm can be applied in a large number of scenarios and is considered a viable option for logistics applications. As regards supply chains, for example, PoA is considered an effective and adequate solution.</p>
                            <p className="pa-p-v1 pa-bright">The Proof of Authority model allows businesses to maintain their privacy and at the same time take advantage of the benefits of blockchain technology. Microsoft Azure is another example of a company applying PoA. In a nutshell, the Azure platform provides solutions for private networks, with a system that does not require a native currency such as ether's 'gas', since the network does not need mining.</p>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
