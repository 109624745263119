import React, { useState } from "react";
import '../../styles/NewsContent.css';
import { Link } from "react-router-dom";

export default function NewsContent({ content }) {
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
  const lupa = `https://${window.location.hostname}/images/lupa.png`;

  const [input, setInput] = useState("");
  const [currentArticles, setCurrentArticles] = useState(content);
  const [statesUpdate, setStateUpdate] = useState(false);
  const [searched, setSearched] = useState(false);

  const handleInput = (e) => {
    setInput(e.target.value);
  }

  const orderArray = (arr) => {
    let orderedArr = arr.sort(function (a, b) {
      if (a.count > b.count) {
        return 1;
      }
      if (a.count < b.count) {
        return -1;
      }
      // a must be equal to b
      return 0;
    });
    return orderedArr;
  }

  const handleButton = (e) => {
    e.preventDefault();
    let currentArticlesArrTitle = [];
    let currentArticlesArrSubtitle = [];
    let currentArticlesArrContent = [];
    content.map((e, i) => {
      let currentObj = { matches: "", count: 0 };
      input.split(" ").map((j, k) => {
        if ((e.title).toLowerCase().includes(j.toLowerCase())) {
          currentObj = { article: e, matches: "title", count: currentObj.count + 1 };
        }
        if ((e.subtitle).toLowerCase().includes(j.toLowerCase()) && currentObj.matches !== "title") {
          currentObj = { article: e, matches: "subtitle", count: currentObj.count + 1 };
        }
        if ((e.content).toLowerCase().includes(j.toLowerCase()) && currentObj.matches !== "title" && currentObj.matches !== "subtitle") {
          currentObj = { article: e, matches: "content", count: currentObj.count + 1 };
        }
      })
      if (currentObj.article) {
        if (currentObj.matches == "title") {
          currentArticlesArrTitle.push(currentObj);
        } else if (currentObj.matches == "subtitle") {
          currentArticlesArrSubtitle.push(currentObj);
        } else if (currentObj.matches == "content") {
          currentArticlesArrContent.push(currentObj);
        }
      }
    })

    currentArticlesArrTitle = orderArray(currentArticlesArrTitle);
    currentArticlesArrSubtitle = orderArray(currentArticlesArrSubtitle);
    currentArticlesArrContent = orderArray(currentArticlesArrContent);

    let currentArticlesArr = currentArticlesArrTitle.concat(currentArticlesArrSubtitle).concat(currentArticlesArrContent);

    let currentArticlesOrdered = [];
    currentArticlesArr.map(e => {
      currentArticlesOrdered.push(e.article);
    })
    setCurrentArticles(currentArticlesOrdered);
    setSearched(input);
    setStateUpdate(!statesUpdate);
  }

  function handleRemove() {
    setSearched(false);
    setCurrentArticles(content);
  }

  return (
    <div style={{ minHeight: `100vh` }}>
      <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, backgroundSize: `cover`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)` }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-10 offset-xl-1 text-center">
              <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>News</strong></h1>
              <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Latest news from revo technologies</p>
            </div>
          </div>
        </div>
      </section>

      <section id="services" style={{ minHeight: `50vh` }} className="pa-what-we-do pa-standard-section text-center">
        <div className="row">
          <form onSubmit={(e) => handleButton(e)} align="left" className="col-12 col-xl-10 offset-xl-1 high-flex">
            <h3>{searched ? <div style={{ display: `flex` }}><span>Searching for: </span><div style={{ display: `flex`, border: "1px solid rgba(167,186,196,.75)", padding: "1px 10px", alignItems: "center", marginLeft: "10px", backgroundColor: "rgba(142,151,161,.13)" }}>{searched + " "}<button style={{ color: "white", border: "1px solid", borderRadius: "60px", padding: "0 7px 3px 7px", fontSize: "16px", height: "70%", marginLeft: "10px" }} onClick={handleRemove}>x</button></div></div> : "Latest Articles"}</h3>
            <div style={{ marginLeft: `auto` }} className="searchbar-input">
              <input onChange={(e) => handleInput(e)} ></input>
              <button type="submit" style={{ position: `absolute`, right: `10px`, marginTop: `0px`, padding: `1px 6px`, border: `none` }}><img style={{ height: `40px` }} src={lupa}></img></button>
            </div>
          </form>

          <div className="container row featured-1 mb-5">{
            <div className="articles-container">
              {currentArticles.map(e => {
                return (
                  <div key={e.title} style={{ backgroundImage: `url(${e.imgUrl.toLowerCase()})`, backgroundPosition: `top` }} className="article pa-hero pa-centered-section pa-full-height pa-image-back pushable-content hc-first-section">
                    <Link className="article-link" to={`/news/${e.title.toLowerCase().replaceAll(" - ", " ").replaceAll(" - ", " ").replaceAll(" ", "-")}`}>Read More</Link>
                    <div className="date-container"><span>{e.date}</span></div>
                    <div className="title-container">
                      <h4>{e.title}</h4>
                      <h5>{e.subtitle}</h5>
                    </div>
                    <div className="article-back"></div>
                  </div>
                )
              })
              }
            </div>}

          </div>
        </div>
      </section>
    </div>
  )
}