import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import FaucetsContent from "./content/FaucetsContent";


export default function Faucets () {

    useEffect(() =>{
        document.title= `Revo | Faucets`
      }, []);     

    return(
        <div>
            <Header />
            <FaucetsContent/>
            <Footer />    
        </div>
    )
}