import React from "react";
import { useEffect } from "react";

export default function LightNet() {
    const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
    const lightning_network = `https://${window.location.hostname}/images/technology/lightning-network.PNG`;

    
    useEffect(() =>{
        document.title= `Revo | Technology - Lightning Network`
      }, []);     


    return (
        <div style={{minHeight: `100vh`}}>

            <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{ backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>



                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-10 offset-xl-1 text-center">
                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10"><strong>Lightning Network</strong></h1>
                            <p className="pa-p-v1 pa-white fadeInDelay03Duration10">The Lightning Network is designed to create transactions as fast and cheap as possible. It’s part of a newer class of crypto technologies known as layer 2 blockchains.</p>

                        </div>
                    </div>
                </div>

            </section>

            <section id="services" className="pa-what-we-do pa-standard-section text-center">

                <div className="container">
                    {/* Section Header */}
                    <div className="row">
                        <div align="left" style={{height: `fit-content`}} className="col-12 col-xl-10 offset-xl-1">

                            <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Lightning Network</h1>                           
                            <p className="pa-p-v1 pa-bright">The Lightning Network is designed to create transactions as fast and cheap as possible. It’s part of a newer class of crypto technologies known as “layer 2” blockchains — which you can think of as being a little like HOV lanes on highways. By offloading some transaction “traffic” to the Lightning Network’s “layer 2” blockchain, the core blockchain (“layer 1”) can move faster.</p>
                            <img style={{width: '80%', height: '30%'}} src={lightning_network}/>
                            <p className="pa-p-v1 pa-bright">As Bitcoin’s value has grown over the years, the narrative has shifted. We now tend to think of Bitcoin as being more like “digital gold” — or as an inflation-resistant way to store wealth over time.</p>
                            <p className="pa-p-v1 pa-bright">Why did that happen? In part it’s because of the way the Bitcoin network is designed. Bitcoin allows two strangers anywhere to securely send or receive value without a credit card company or payment processor in the middle. It does this using a decentralized network of computers all over the world — all of which need to achieve consensus (or agree) about the current state of Bitcoin’s digital ledger.</p>
                            <p className="pa-p-v1 pa-bright">Nakamoto’s solution to this problem was mining, which is a time-consuming and not environmental friendly process. The Lightning Network was invented, in part, to help Bitcoin function more like the digital cash that Nakamoto envisioned. It processes transactions “off-chain” much more quickly and cheaply than Bitcoin’s core blockchain — with fees that are typically fractions of a cent. Lightning transactions are also less energy intensive than transactions on the main blockchain. While the main Bitcoin blockchain (layer 1) can typically handle fewer than 10 transactions per second, the Lightning Network (layer 2) can theoretically handle millions of transactions a second.</p>
                            <p className="pa-p-v1 pa-bright">The Lightning Network uses smart contracts to establish off-blockchain payment channels between pairs of users. Once these payment channels are established, funds can be transferred between them almost instantly. Cleverly, the network doesn’t need to create pairs between all users. For instance, if User A has a channel with User B, and User C has a channel with User B but not User A, funds can still be freely transferred between all networked parties. Lightning addresses look like typical Bitcoin addresses, and the payment process is very similar for users.</p>
                            <p className="pa-p-v1 pa-bright">At any time, users can close their payment channels and settle their final balances on the core blockchain. Because only the opening and closing of payment channels are recorded on the core blockchain, the entire network can move faster. Additionally, Lightning Network transactions can be more private than those made on the main blockchain (because layer 1 transactions all appear on a public and transparent ledger).</p>
                            <h1 id="compatibility" className="pa-h1-v2 pa-bright fadeInDelay00Duration10">Compatibility</h1>
                            <p className="pa-p-v1 pa-bright">Thanks to the UTXO Model that Revo applies for his own address/balance structure, compatibility with Lightning protocol is already granted on full scale.</p>  
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}
