import React from "react";
import { useEffect } from "react";

export default function Erc721() {
  const nebulosa = `https://${window.location.hostname}/images/nebula.png`;
  
  useEffect(() =>{
    document.title= `Revo | API - Erc721`
  }, []);     

    return (
        <div style={{minHeight: `100vh`}}>
        <section className="pa-cta-v2 pa-centered-section pa-image-back" style={{backgroundImage: `url(${nebulosa})`, boxShadow: `inset 5px -20px 15px rgb(0,0,0)`}}>
    
            
    
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl-10 offset-xl-1 text-center">
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">ERC721</h1>
                        <p className="pa-p-v1 pa-white fadeInDelay03Duration10">Get information data of ERC721 contracts.</p>
                        
                        </div>
                </div>
            </div>
    
        </section>
        
        <section id="services" className="pa-what-we-do pa-standard-section text-center">
    
            <div className="container">
                {/* Section Header */}
                <div className="row">
                    <div align="left" className="col-12 col-xl-10 offset-xl-1">
        
                        <h1 className="pa-h1-v2 pa-bright fadeInDelay00Duration10">ERC721</h1>					
                        <font size="5" color="#7CFC00"><i className="fa fa-lock" aria-hidden="true"></i> SSL</font> <font size="5" color="white">URL</font><h4 className="pc-first-h4">https://api.revo.network</h4>
                        <p className="pa-p-v1 pa-bright">Data is served over secure socket layer, avoiding any kind of issue with CORS policies. Online dataset is retrieved by single fully indexed Revo Core node istance powering current official block explorer.</p>
                        
                        <font color="white" style={{fontSize: `26px !important`}}>Table of contents</font><br></br>
                        <ul style={{color: `white`}}>
                          <li><b><a style={{color: `white`}} href="#erc721">ERC721</a></b>
                            <ul>
                              <li><a style={{color: `white`}} href="#erc721-list">ERC721 list</a></li>
                            </ul>
                          </li>				  
                        </ul>
                        
                        <br></br>
                        
                        <font id="erc721"color="white" style={{fontSize: `26px !important`}}>ERC721</font>
                        <p className="pa-p-v1 pa-bright">Get information data of ERC721 contracts.</p>					
                        <font id="erc721-list" color="white">ERC721 list</font>
                        <table style={{width: `100%`}}>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="#7CFC00"><i className="fa fa-server" aria-hidden="true"></i> {window.innerWidth < 660 ? <span>[GET] <br></br>Request</span> : "[GET] Request"}</font></td>
                            <td ><font className="ap-first-h4">/erc20</font></td>
                          </tr>
                          <tr>
                                    <td style={window.innerWidth < 660 ? { width: `100px` } : { width: `15vw` }}><font color="yellow"><i className="fa fa-check" aria-hidden="true"></i> (JSON) Response</font></td>
                            <td><p style={{marginBottom: `10px !important`}} className="pa-p-v1 pa-bright">List all ERC721 tokens</p>	
                                <font color="#1fcecb">Examples</font><br></br>
                                <a className="exampleapi" href="https://api.revo.network/erc721" target="_blank">https://api.revo.network/contract/erc721</a><br></br>
                            </td>
                          </tr>
                        </table>	
                    </div>
                </div>
            </div>
        </section>
        

        </div>
    )
}
