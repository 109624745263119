import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import TechnologyContent from "./content/TechnologyContent";
import { useEffect } from "react";


export default function Technology () {

    useEffect(() =>{
        document.title= `Revo | Technology`
      }, []);     

    return(
        <div>
            <Header />
            <TechnologyContent/>
            <Footer />    
        </div>
    )
}