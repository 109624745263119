import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import { useEffect } from "react";
import ComingSoon from './ComingSoon';


export default function Ecosystem () {

    useEffect(() =>{
        document.title= `Revo | Ecosystem`
      }, []);     

    return(
        <div>
            <Header />
            <ComingSoon />            
            <Footer />    
        </div>
    )
}