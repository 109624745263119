import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import PublicApi from './components/PublicApi';
import ApiDocs from './components/ApiDocs';
import NodeMap from './components/NodeMap';
import News from './components/News';
import Article from './components/Article';
import Partners from './components/Partners';
import Technology from './components/Technology';
import Ecosystem from './components/Ecosystem';
import TechDocs from './components/TechDocs';
import Members from './components/Members';
import NotarizeDocument from './components/NotarizeDocument';
import DeFi from './components/DeFi';
import Domains from './components/Domains';
import CreateNft from './components/CreateNft';
import ProjectDocs from './components/ProjectDocs';
import Develop from './components/Develop';
import Build from './components/Build';
import Deploy from './components/Deploy';
import Tutorials from './components/Tutorials';
import Wiki from './components/Wiki';
import Training from './components/Training';
import Error404 from './components/Error404';
import NetworkStats from './components/NetworkStats';
import Faucets from './components/Faucets';
import Profiles from './components/Profiles';


// Import scss
import "./styles/assets/scss/theme.scss"

function App() {
  return (
    <BrowserRouter>
    <div className="App">
      <Routes>
        <Route exact path='/' element= {<Home/>} />
        <Route exact path='/public-api' element= {<PublicApi/>} />
        <Route exact path='/docs-api/:content' element= {<ApiDocs/>} />
        <Route path='/docs-api' element= {<ApiDocs/>} />
        <Route exact path='/map' element= {<NodeMap/>} />
        <Route exact path='/network-stats' element= {<NetworkStats />} />
        <Route exact path='/news' element= {<News/>} />
        <Route exact path='/news/:article' element= {<Article/>} />
        <Route exact path='/partners' element= {<Partners/>} />
        <Route exact path='/technology' element= {<Technology/>} />
        <Route exact path='/ecosystem' element= {<Ecosystem/>} />
        <Route exact path='/technology/:content' element= {<TechDocs/>} />
        <Route exact path='/members' element= {<Members/>} />        
        <Route exact path='/notarize-document' element= {<NotarizeDocument/>} />
        <Route exact path='/defi' element= {<DeFi/>} />
        <Route exact path='/domains' element= {<Domains />} />
        <Route exact path='/create-nft' element= {<CreateNft />} />
        <Route exact path='/project-docs' element= {<ProjectDocs />} />
        <Route exact path='/develop' element= {<Develop />} />
        <Route exact path='/build' element= {<Build />} />
        <Route exact path='/deploy' element= {<Deploy />} />
        <Route exact path='/wiki' element= {<Wiki/>} />
        <Route exact path='/training' element= {<Training/>} />
        <Route exact path='/tutorials' element= {<Tutorials/>} />
        <Route exact path='/testnet-faucets' element= {<Faucets/>} />
        <Route exact path='/profiles' element= {<Profiles />} />
        <Route path='*' element= {<Error404/>} />

      </Routes>
    </div>
    </BrowserRouter>
  );
}

export default App;
